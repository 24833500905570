<template>
    <div class="contact">
        <!-- <div class="advantage">
            <div class="title">
                {{ "mission" | t }}
            </div>
            <div class="line"></div>
            <div class="adv-info">
                {{ "contactSub" | t }}
            </div>
        </div> -->
        <div class="ec-main">
            <div class="ecTitle">{{ "partner" | t }}</div>
            <em></em>
            <div class="coWrap">
                <div class="ecWrap">
                    <div
                        v-for="(chat, i) in listData"
                        :key="i"
                        class="ecContent"
                    >
                        <img
                            :src="chat.img"
                            :alt="chat.alt"
                            @click="linkUrl(chat.link)"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pearcoin from '@/assets/images/logoIcon/pearcoin.png'
import CoinGalaxy from '@/assets/images/logoIcon/CoinGalaxy.png'
import KonsorTech from '@/assets/images/logoIcon/KonsorTech.png'
import NodeX from '@/assets/images/logoIcon/NodeX.png'

export default {
    name: 'contact-page',
    data() {
        return {
            listData: [
                {
                    alt: 'pearcoin',
                    img: pearcoin,
                    link: 'https://www.pearcoin.ltd/'
                },
                {
                    alt: 'CoinGalaxy',
                    img: CoinGalaxy,
                    link: window.localStorage.getItem('language') === 'zh-CN' ? 'https://www.cgxyex.co/#/' : 'https://www.cgxyex.com/#/'
                },
                {
                    alt: 'KonsorTech',
                    img: KonsorTech,
                    link: 'https://konsortech.xyz/'
                },
                {
                    alt: 'NodeX',
                    img: NodeX,
                    link: 'https://indonode.dev/'
                }
            ]
        }
    },
    methods: {
        linkUrl(url) {
            if (url) {
                if (url === 'https://www.cgxyex.co/#/' || url === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(url)
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.contact {
    background: #081421;
    width: 100%;
    // min-height: 100vh;
    color: #fff;
    padding-bottom: 116px;
    overflow-y: hidden;

    .advantage {
        width: calc(100% - 300px);
        padding: 10% 150px 150px;
        background-image: url('../assets/images/contactBg.png');
        background-size: 80%;
        // background-size: 40% 100%;
        background-repeat: no-repeat;
        background-position: 100% 0%;

        .title {
            width: 780px;
            color: white;
            font-size: 54px;
            font-weight: bold;
            line-height: 76px;
        }

        .line {
            width: 94px;
            height: 8px;
            background: #3c5ef1;
            border-radius: 4px;
            margin-top: 38px;
        }

        .adv-info {
            width: 60%;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            margin-top: 44px;
        }
    }

    .ecTitle {
        text-align: center;
        font-size: 48px;
        font-weight: bold;
    }

    em {
        width: 94px;
        height: 8px;
        border-radius: 4px;
        background-color: #0043b3;
        display: block;
        margin: 25px auto 90px;
    }

    .coWrap {
        background: #141f2b;
        // margin-bottom: 116px;

        .ecWrap {
            padding: 90px 150px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            // line-height: 1.5;

            .ecContent {
                padding: 50px 75px;

                img {
                    // width: 60px;
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    @media only screen and (max-width: 990px) {
        & {
            padding-bottom: 256px;
        }

        .advantage {
            width: calc(100% - 300px);
            padding: 400px 150px 600px 150px;
            // background-size: 100% 100%;
            background-size: 110%;
            background-repeat: no-repeat;
            background-position: 200% 100%;

            .title {
                width: calc(100%);
                color: white;
                font-size: 88px;
                font-weight: bold;
                line-height: 76px;
            }

            .line {
                width: 254px;
                height: 8px;
                background: #3c5ef1;
                border-radius: 4px;
                margin-top: 58px;
            }

            .adv-info {
                width: calc(100%);
                font-size: 66px;
                font-weight: 500;
                color: #ffffff;
                margin-top: 44px;
            }
        }

        .ecTitle {
            font-size: 80px;
        }

        em {
            width: 253px;
            height: 12px;
            border-radius: 10px;
            background-color: #0043b3;
            display: block;
            margin: 45px auto 120px;
        }

        .coWrap {
            background: #141f2b;

            .ecWrap {
                justify-content: space-between;
                .ecContent {
                    img {
                        width: 250px;
                    }
                }
            }
        }
    }
}
</style>
