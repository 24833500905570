<template>
    <div class="footer">
        <div class="footer-nav">
            <div class="footer-title w" @click="jumper('home')">
                <img src="../assets/images/logo2.png" alt="">
                <p>
                    <span>ICWChain is an innovative public chain network that takes into account decentralization</span><br>
                    <span>high performance and data security</span>
                </p>
            </div>
            <div class="footer-nav-list w">
                <dl class="mod_help_item">
                    <dt class="title" @click="jumper('home')">{{ "aboutICW" | t }}</dt>
                    <!-- <dd @click="jumper('technology')">{{ "whitePaper" | t }}</dd> -->
                    <dd @click="jumper('home')">{{ "Introduction" | t }}</dd>
                    <!---<dd @click="jumper('partner')">{{ "partner" | t }}</dd>-->
                    <dd>{{ "partner" | t }}</dd>
                </dl>
                <dl class="mod_help_item">
                    <dt @click="jumper('technology')" class="title">
                        {{ "technology" | t }}
                    </dt>
                    <dd @click="jumper('technology')">{{ "whitePaper" | t }}</dd>
                    <dd @click="jumper('technology')">{{ "technologyFunctions" | t }}</dd>
                    <dd @click="jumper('technology')">{{ "roadmap" | t }}</dd>
                </dl>
                <dl class="mod_help_item">
                    <dt @click="jumper('ecological')" class="title">
                        {{ "ecological" | t }}
                    </dt>
                    <dd @click="jumper('ecological')">{{ "ecological" | t }}</dd>
                    <dd @click="jumper('recruitment')">{{ "recruitment" | t }}</dd>
                    <dd @click="jumper('http://browser.icwchain.com')">{{ "browser" | t }}</dd>
                    <dd @click="jumper('http://wallet.icwchain.com')">{{ "wallet" | t }}</dd>
                </dl>

                <dl class="mod_help_item">
                    <dt @click="jumper('contact')" class="title">{{ "contact" | t }}</dt>
                    <!-- <dd @click="jumper('joinUs')">{{ "joinUs" | t }}</dd> -->
                    <dd @click="jumper('https://discord.com/channels/983604567205900318/983617925560811581')"
                        @mouseover="mouseOverJOINUS(1)" @mouseleave="mouseLeaveJOINUS(1)">
                        Discord
                    </dd>
                    <dd @click="jumper('https://t.me/ICWChaincomm')" @mouseover="mouseOverJOINUS(2)"
                        @mouseleave="mouseLeaveJOINUS(2)">
                        <span>Telegram</span>
                    </dd>
                    <dd @click="jumper('https://github.com/icwchain')" @mouseover="mouseOverJOINUS(4)"
                        @mouseleave="mouseLeaveJOINUS(4)">
                        <span>Github</span>
                    </dd>
                    <dd @click="jumper('https://twitter.com/IcwChain')" @mouseover="mouseOverJOINUS(0)"
                        @mouseleave="mouseLeaveJOINUS(0)">
                        Twitter
                    </dd>
                </dl>
            </div>
        </div>
        <div class="copyright">
            <div class="w">
                <p> Copyright © 2023 EDAO DEVELOPMENT FOUNDATION LTD. ALL Rights Reserved</p>
            </div>
        </div>
    </div>
</template>

<script>
import pearcoin1 from '@/assets/images/logoIcon/pearcoin-1.png'
import CoinGalaxy1 from '@/assets/images/logoIcon/CoinGalaxy-1.png'
import KonsorTech1 from '@/assets/images/logoIcon/KonsorTech-1.png'
import NodeX1 from '@/assets/images/logoIcon/NodeX-1.png'
import pearcoin from '@/assets/images/logoIcon/pearcoin.png'
import CoinGalaxy from '@/assets/images/logoIcon/CoinGalaxy.png'
import KonsorTech from '@/assets/images/logoIcon/KonsorTech.png'
import NodeX from '@/assets/images/logoIcon/NodeX.png'
import twitter from '@/assets/images/logoIcon/twitter-icon.png'
import discord from '@/assets/images/logoIcon/discord-icon.png'
import telegram from '@/assets/images/logoIcon/telegram-icon.png'
import facebook from '@/assets/images/logoIcon/facebook-icon.png'
import github from '@/assets/images/logoIcon/github-icon.png'
import medium from '@/assets/images/logoIcon/medium-icon.png'
import youtube from '@/assets/images/logoIcon/youtube-icon.png'
import twitter1 from '@/assets/images/logoIcon/twitter-icon-1.png'
import discord1 from '@/assets/images/logoIcon/discord-icon-1.png'
import telegram1 from '@/assets/images/logoIcon/telegram-icon-1.png'
import facebook1 from '@/assets/images/logoIcon/facebook-icon-1.png'
import github1 from '@/assets/images/logoIcon/github-icon-1.png'
import medium1 from '@/assets/images/logoIcon/medium-icon-1.png'
import youtube1 from '@/assets/images/logoIcon/youtube-icon-1.png'

export default {
    name: 'page-footer',
    data() {
        return {
            year: new Date().getFullYear(),
            listData: [
                {
                    alt: 'pearcoin',
                    img: pearcoin1,
                    link: 'https://www.pearcoin.ltd/'
                },
                {
                    alt: 'CoinGalaxy',
                    img: CoinGalaxy1,
                    link: window.localStorage.getItem('language') === 'zh-CN' ? 'https://www.cgxyex.co/#/' : 'https://www.cgxyex.com/#/'
                },
                {
                    alt: 'KonsorTech',
                    img: KonsorTech1,
                    link: 'https://konsortech.xyz/'
                },
                {
                    alt: 'NodeX',
                    img: NodeX1,
                    link: 'https://indonode.dev/'
                }
            ],
            twitter,
            discord,
            telegram,
            facebook,
            github,
            medium,
            youtube
        }
    },
    methods: {
        /**
         * 死路由跳转
         */
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        // 合作伙伴鼠标移入
        mouseOver(data) {
            if (data === 'pearcoin') {
                this.listData[0].img = pearcoin
            } else if (data === 'CoinGalaxy') {
                this.listData[1].img = CoinGalaxy
            } else if (data === 'KonsorTech') {
                this.listData[2].img = KonsorTech
            } else if (data === 'NodeX') {
                this.listData[3].img = NodeX
            }
        },
        // 合作伙伴鼠标移出
        mouseLeave(data) {
            if (data === 'pearcoin') {
                this.listData[0].img = pearcoin1
            } else if (data === 'CoinGalaxy') {
                this.listData[1].img = CoinGalaxy1
            } else if (data === 'KonsorTech') {
                this.listData[2].img = KonsorTech1
            } else if (data === 'NodeX') {
                this.listData[3].img = NodeX1
            }
        },
        // 加入我们鼠标移入
        mouseOverJOINUS(index) {
            if (index === 0) {
                this.twitter = twitter1
            } else if (index === 1) {
                this.discord = discord1
            } else if (index === 2) {
                this.telegram = telegram1
            } else if (index === 3) {
                this.facebook = facebook1
            } else if (index === 4) {
                this.github = github1
            } else if (index === 5) {
                this.medium = medium1
            } else if (index === 6) {
                this.youtube = youtube1
            }
        },
        // 合作伙伴鼠标移出
        mouseLeaveJOINUS(index) {
            if (index === 0) {
                this.twitter = twitter
            } else if (index === 1) {
                this.discord = discord
            } else if (index === 2) {
                this.telegram = telegram
            } else if (index === 3) {
                this.facebook = facebook
            } else if (index === 4) {
                this.github = github
            } else if (index === 5) {
                this.medium = medium
            } else if (index === 6) {
                this.youtube = youtube
            }
        }
    }
}
</script>

<style lang="less">
.w {
    width: 1200px;
    margin: 0 auto;
}

.footer {
    width: 100%;
    height: 624px;
    background-color: #001643;

    .footer-nav {

        .footer-title {
            box-sizing: border-box;
            height: 230px;
            padding-top: 100px;
            border-bottom: 1px solid #0C2D66;

            img {
                float: left;
                padding-left: 40px;
            }

            p {
                font-weight: 400;
                font-family: 'Poppins';
                float: right;
                font-size: 18px;
                color: #FFFFFF;
                text-align: right;

                span:last-child {
                    font-weight: 100;
                }
            }
        }

        .footer-nav-list {
            display: flex;
            width: 1200px;
            height: 334px;
            margin: 0 auto;
            justify-content: space-between;

            .mod_help_item {
                padding-top: 60px;

                dt {
                    font-family: 'Poppins';
                    height: 27px;
                    line-height: 27px;
                    color: #FFFFFF;
                    font-size: 18px;
                    padding-bottom: 20px;
                    cursor: pointer;
                }

                dd {
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }

        /*@media only screen and (max-width: 990px) {
            .copyright {
                font-size: 16px;
                line-height: 1.6;
                padding: 12px;
            }
        }*/
    }

    .copyright {
        font-family: '思源黑体';
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #1F2743;
        color: #FFFFFF;
    }

}
</style>
<!-- 移动端-->
<style lang="less">
@media only screen and (max-width: 990px) {
    .footer {
        height: 2.32rem;

        .footer-nav {
            .w {
                width: 6.1rem;
            }

            .footer-title {
                padding-top: 0;
                height: 1.5rem;
                border-bottom: 0;

                p {
                    margin-top: 0.5rem;
                    font-size: 0.17rem;
                    text-align: left;
                    Opacity:0.6;
                }
            }
        }

        .copyright {
            height: 1.04rem;
            line-height: 0.52rem;
            .w {
                width: 6.1rem;
                p {
                    font-size: 0.15rem;
                }
            }
        }
    }

}
</style>
