<template>
    <div class="ecological">

        <div class="advantage">
            <div class="w">
                <div class="title">
                    {{ 'ICWCHAIN' | t }} <br> <b>{{ 'recruitment' | t }}</b>
                </div>
                <div class="adv-info">
                    {{ 'adopts' | t }}
                </div>
            </div>
        </div>

        <div class="node">
            <div class="w">
                <div class="why">
                    <h2> {{ 'ICWNode' | t }}</h2>
                    <em></em>
                    <h5>{{ 'incentive' | t }}</h5>
                </div>
                <div class="adv-child fl">
                    <div class="line">
                        <img src="../assets/images/section.png" alt="">
                    </div>
                    <div class="title1">
                        {{ 'section' | t }}
                    </div>
                    <div class="content">
                        {{ 'bookkeeping' | t }}
                    </div>
                </div>
                <div class="adv-child fl">
                    <div class="line">
                        <img src="../assets/images/node.png" alt="">
                    </div>
                    <div class="title1">
                        {{ 'ServiceNode' | t }}
                    </div>
                    <div class="content">
                        {{ 'servicenode' | t }}
                    </div>
                </div>
                <div class="adv-child fr">
                    <div class="line">
                        <img src="../assets/images/income.png" alt="">
                    </div>
                    <div class="title1">
                        {{ 'Grossincome' | t }}
                    </div>
                    <div class="content">
                        {{ 'Grossincomes' | t }}
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="advantageWrap">
            <div class="advantage">
                <div class="advLeft">
                    <div class="title">{{ "recruitTit" | t }}</div>
                    <div class="btns">
                        <div @click="download('userManual')">{{ "userManual" | t }}</div>
                        <div @click="download('selfCreation')">
                            {{ "selfCreation" | t }}
                        </div>
                        <div @click="downloadTAR('http://8.219.130.70:8002/download/ICW_Wallet.tar')">
                            {{ "installtion" | t }}
                        </div>
                        <div @click.stop="openDialog">
                            {{ "replaceCreation" | t }}
                        </div>
                    </div>
                </div>
                <div class="advRight">
                    <div class="title">
                        <div>{{ "advTitle1" | t }}</div>
                        <div>{{ "advTitle2" | t }}</div>
                    </div>
                    <div class="line"></div>
                    <div class="advContent">
                        <div>{{ "advContent1" | t }}</div>
                        <div>{{ "advContent2" | t }}</div>
                    </div>
                </div>
            </div>
            <div class="topTimesWrap pc">
                <div class="timeWrap">
                    <div class="title">
                        {{ "nrTitle" | t }}
                    </div>
                    <div class="time">{{ "nrTime" | t }}</div>
                    <div class="content">{{ "nrContetn1" | t }}</div>
                    <div class="content">{{ "nrContetn2" | t }}</div>
                </div>
                <div class="timeWrap">
                    <div class="title">
                        {{ "ndTitle" | t }}
                    </div>
                    <div class="time">
                        <div>{{ "ndTime" | t }}</div>
                        <div>{{ "ndTime1" | t }}</div>
                    </div>
                    <div class="content">{{ "ndContetn1" | t }}</div>
                </div>
                <div class="topTimesBg"></div>
            </div>
        </div>
        <div class="topTimesWrap wap">
            <div class="timeWrap">
                <div class="title">
                    {{ "nrTitle" | t }}
                </div>
                <div class="time">{{ "nrTime" | t }}</div>
                <div class="content">{{ "nrContetn1" | t }}</div>
                <div class="content">{{ "nrContetn2" | t }}</div>
            </div>
            <div class="timeWrap">
                <div class="title">
                    {{ "ndTitle" | t }}
                </div>
                <div class="time">
                    <div>{{ "ndTime" | t }}</div>
                    <div>{{ "ndTime1" | t }}</div>
                </div>
                <div class="content">{{ "ndContetn1" | t }}</div>
            </div>
            <div class="topTimesBg"></div>
        </div>
        <div class="ec-main">
            <div class="ecTitle">{{ "recruitmentTit" | t }}</div>
            <div class="ecWrap">
                <div
                    v-for="(item, index) in planBenefits"
                    :key="index"
                    class="ecContent"
                >
                    <div class="contentImg">
                        <img
                            :src="item.icon"
                            :alt="item.title"
                        />
                    </div>
                    <div class="contentTitle">
                        {{ item.title | t }}
                    </div>
                    <div class="contentContent">
                        {{ item.content | t }}
                    </div>
                </div>
            </div>
            <div class="nodeCreate">
                <div class="nctitle">
                    {{ "nodeCreation" | t }}
                </div>
                <div class="tabsWrap">
                    <div
                        :class="{ active: activeIndex == 0, tabs: true }"
                        @click="changeTab(0)"
                    >
                        {{ "qualification" | t }}
                    </div>
                    <div
                        :class="{ tabs: true, active: activeIndex == 1 }"
                        @click="changeTab(1)"
                    >
                        {{ "process" | t }}
                    </div>
                    <div
                        :class="{ tabs: true, active: activeIndex == 2 }"
                        @click="changeTab(2)"
                    >
                        {{ "pledge" | t }}
                    </div>
                </div>
            </div>
            <div class="tabview">
                <div
                    v-show="activeIndex == 0"
                    class="ai1Wrap"
                >
                    <div
                        v-for="(item, index) in tabviewList1"
                        :key="index"
                    >
                        <div
                            class="ai1Bg"
                            style=""
                        >
                            <div class="icon">
                                <div></div>
                            </div>
                        </div>
                        <div class="title">{{ item.title | t }}</div>
                    </div>
                </div>
                <div v-show="activeIndex == 1">
                    <div class="ai2Wrap">
                        <div class="ai2Flex">
                            <div
                                v-for="(item, index) in tabviewList2"
                                :key="index"
                            >
                                <div
                                    v-if="index == 0"
                                    class="ai2Bg ai2Index0"
                                >
                                    <div class="title">{{ "serverSetup" | t }}</div>
                                    <div class="content">
                                        <div>{{ "serverSetup1" | t }}</div>
                                        <div>{{ "serverSetup2" | t }}</div>
                                    </div>
                                </div>
                                <div
                                    class="ai2Bg"
                                    v-else
                                >
                                    <img
                                        :src="item.icon"
                                        alt="icon"
                                        srcset=""
                                    />
                                    <div class="title">{{ item.title | t }}</div>
                                </div>
                                <div class="indexText">{{ "0" + (index + 1) }}</div>
                            </div>
                        </div>
                        <div class="indexLine"></div>
                    </div>
                </div>
                <div v-show="activeIndex == 2">
                    <div class="ai3Wrap">
                        <div class="ai3Flex">
                            <div
                                v-for="(item, index) in tabviewList3"
                                :key="index"
                            >
                                <div class="ai3Bg">
                                    <img
                                        :src="item.icon"
                                        alt="icon"
                                        srcset=""
                                    />
                                    <div class="title">{{ item.title | t }}</div>
                                </div>
                                <div class="indexText">{{ "0" + (index + 1) }}</div>
                            </div>
                        </div>
                        <div class="indexLine"></div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="ec-dialog"
            v-if="agentDialogVisible"
            @click.stop
        >
            <div class="ec-dialog-title">
                {{ 'agentConstruction' | t }}
            </div>
            <div class="ec-form">
                <label
                    for="account"
                    class="ec-form-item"
                    :class="{ error: error === 'user' }"
                >
                    <span>*</span>
                    <input
                        type="text"
                        class="ec-account"
                        id="account"
                        v-model.trim="userName"
                        maxlength="100"
                        :placeholder="t('account')"
                        @focus="error = ''"
                    >
                </label>
                <label
                    for="mobile"
                    class="ec-form-item"
                    :class="{ error: error === 'phone' }"
                >
                    <span>*</span>
                    <input
                        type="text"
                        class="ec-mobile"
                        id="mobile"
                        v-model.trim="phone"
                        maxlength="18"
                        :placeholder="t('mobileAccount')"
                        @focus="error = ''"
                    >
                </label>
                <label
                    for="email"
                    class="ec-form-item"
                    :class="{ error: error === 'email' }"
                >
                    <span>*</span>
                    <input
                        type="text"
                        class="ec-email"
                        id="email"
                        maxlength="50"
                        :placeholder="t('emailAccount')"
                        v-model.trim="email"
                        @focus="error = ''"
                    >
                </label>
            </div>
            <div
                class="ec-form--button"
                @click="preRegister"
            >
                {{ 'apply' | t }}
            </div>
            <ul class="ec-dialog--info">
                <li>{{ 'infoText1' | t}}</li>
                <li>{{ 'infoText2' | t}}</li>
            </ul>
            <div class="thrid-service">
                {{ 'thirdPartyServices' | t }}
            </div>
        </div>-->
    </div>
</template>

<script>
import downloadjs from 'downloadjs'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { t } from '@/assets/i18n/index'

export default {
    name: 'recruitment-page',
    data() {
        return {
            planBenefits: [
                {
                    icon: require('@/assets/images/accountingIcon.png'),
                    title: 'accountingTitle',
                    content: 'accountingContent'
                },
                {
                    icon: require('@/assets/images/nodeIcon.png'),
                    title: 'nodeTitle',
                    content: 'nodeContent'
                },
                {
                    icon: require('@/assets/images/profitIcon.png'),
                    title: 'profitTitle',
                    content: 'profitContent'
                }
            ],
            activeIndex: 0,
            tabviewList1: [
                {
                    title: 'networkEnvironmentSettings',
                    bg: require('@/assets/images/networkConfigBg.png'),
                    icon: require('@/assets/images/networkConfigIcon.png')
                },
                {
                    title: 'deposit',
                    bg: require('@/assets/images/bondBg.png'),
                    icon: require('@/assets/images/bondIcon.png')
                }
            ],
            tabviewList2: [
                {
                    title: 'serverSetup'
                },
                {
                    title: 'loginWallet',
                    icon: require('@/assets/images/loginWallet.png')
                },
                {
                    title: 'fillinInfo',
                    icon: require('@/assets/images/fillinInfo.png')
                },
                {
                    title: 'depositPayment',
                    icon: require('@/assets/images/bondIcon.png')
                },
                {
                    title: 'creationSucceeded',
                    icon: require('@/assets/images/creationSucceeded.png')
                }
            ],
            tabviewList3: [
                {
                    title: 'loginWallet',
                    icon: require('@/assets/images/loginWallet.png')
                },
                {
                    title: 'ICWPledge',
                    icon: require('@/assets/images/pledge.png')
                },
                {
                    title: 'nodeOperation',
                    icon: require('@/assets/images/nodeOperation.png')
                }
            ],
            agentDialogVisible: false,
            error: '', // 未填数据项
            email: '',
            phone: '',
            userName: ''
        }
    },
    computed: {
        lang() {
            return this.$store.getters.getLanguage
        }
    },
    created() {
        window.addEventListener('click', this.closeDialog)
    },
    methods: {
        t,
        changeTab(index) {
            this.activeIndex = index
        },
        download(name) {
            if (this.lang === 'zh-CN') {
                let pdfUrl
                name && name === 'userManual' && (pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/yonghushouceCN.pdf')
                name && name === 'selfCreation' && (pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/Self%20Creation%20Chinese%20version.pdf')
                downloadjs(pdfUrl)
            } else {
                const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/' + name + '.pdf'
                downloadjs(pdfUrl)
            }
        },
        downloadTAR(tar) {
            // downloadjs(tar)
            window.open(tar)
        },
        openDialog() {
            this.agentDialogVisible = true
            disablePageScroll()
        },
        closeDialog() {
            this.agentDialogVisible = false
            this.userName = ''
            this.phone = ''
            this.email = ''
            enablePageScroll()
        },
        preRegister() {
            if (!this.userName) {
                this.error = 'user'
            } else if (!this.phone) {
                this.error = 'phone'
            } else if (!this.email) {
                this.error = 'email'
            } else {
                this.$ajax.post('/edaoadminfinance/pre_register/save', {
                    userName: this.userName,
                    phone: this.phone,
                    email: this.email
                }).then(() => {
                    this.closeDialog()
                    alert(`${t('succeed')} !`)
                })
            }
        }
    },
    beforeRouteLeave() {
        window.removeEventListener('click', this.closeDialog)
        enablePageScroll()
    }
}
</script>
<style lang="less" scoped>
.ecological {
    .advantage {
        font-family: 'Poppins';
        width: 100%;
        height: 717px;
        background: url('../assets/images/bg4.png') no-repeat center;
        margin: 0 auto;

        .w {
            position: relative;

            .title {
                font-family: 'MiSans';
                position: absolute;
                top: 135px;
                left: 84px;
                width: 758px;
                font-weight: 700;
                font-size: 64px;
                color: #021829;

                b {
                    position: absolute;
                    top: 85px;
                    color: #246EFF;
                }
            }

            .adv-info {
                position: absolute;
                top: 325px;
                left: 84px;
                width: 758px;
                height: 112px;
                line-height: 28px;
                font-size: 16px;
                color: #777E90;
            }
        }

    }

    .node {
        font-family: 'Poppins';
        width: 100%;
        height: 1016px;
        background-color: #FFFFFF;

        .why {
            color: #424242;
            width: 600px;
            text-align: center;
            margin: 100px auto 0;

            h2 {
                font-size: 36px;
            }

            em {
                display: block;
                width: 39px;
                height: 1px;
                margin: 25px auto;
                text-align: center;
                background-color: #94A5C5;
            }

            h5 {
                font-weight: normal;
                color: #94A5C5;
                font-size: 16px;
            }
        }

        .adv-child {
            box-sizing: border-box;
            padding: 57px 0 0 57px;
            margin-top: 80px;
            width: 380px;
            height: 560px;
            border: 1px solid #EBF1FF;
        }

        .adv-child:nth-child(3) {
            margin: 80px 30px 0;
        }

        .adv-child .title1 {
            font-size: 22px;
            color: #424242;
            font-weight: 700;
            margin: 25px 0;
        }

        .adv-child .content {
            width: 300px;
            line-height: 30px;
            font-size: 18px;
            color: #7F8CA5;
        }
    }

    /*    .advantageWrap {
        background: url(../assets/images/recruitmentBg.png) no-repeat;
        background-size: cover;
        background-position: bottom;
        height: calc(100vh + 400px);
        color: #fff;
        position: relative;

        .advantage {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 300px);
            height: calc(100vh - 120px);
            margin: 0 150px;
            align-items: center;

            .advLeft {
                .title {
                    font-size: 56px;
                    font-weight: bold;
                    margin-bottom: 86px;
                }

                .btns {
                    display: flex;
                    flex-wrap: wrap;

                    & > div {
                        background: #6a84c3;
                        margin-right: 38px;
                        padding: 8px 20px;
                        border-radius: 10px;
                        font-size: 22px;
                        cursor: pointer;
                        margin-bottom: 40px;
                    }
                }
            }

            .advRight {
                background: rgba(41, 182, 255, 0.4);
                backdrop-filter: blur(20px) brightness(130%);
                box-shadow: none;
                padding: 90px 55px 90px;
                border-radius: 30px;
                width: 610px;

                .title {
                    font-size: 30px;
                }

                .advContent {
                    font-size: 20px;
                }

                .line {
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    margin: 30px 0;
                }
            }
        }

        .topTimesWrap {
            display: block;
            position: absolute;
            bottom: 0;
            // background: url(../assets/images/frosting.png) no-repeat;
            background-size: 100% 100%;
            padding: 0 150px;
            width: calc(100% - 300px);
            display: flex;
            justify-content: space-between;

            .topTimesBg {
                width: 100%;
                height: 100%;
                flex: 1;
                position: absolute;
                left: 0;
                top: 0;
                // background-image: url(../assets/images/recruitmentBg.png);
                // background-color: rgba(255, 255, 255, 0.3);
                // background-position: bottom;
                // background-size: 100% auto;
                backdrop-filter: blur(20px) brightness(130%);
                box-shadow: none;
            }

            .timeWrap {
                width: 40%;
                text-align: center;
                position: relative;
                z-index: 1;

                .title {
                    font-size: 34px;
                    font-weight: 400;
                    // background: red;
                    display: inline-block;
                    padding: 38px 103px;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                    margin-bottom: 38px;
                    position: relative;
                    overflow: hidden;
                    backdrop-filter: blur(20px) brightness(130%);
                    box-shadow: none;
                }

                .time {
                    height: 75px;
                    font-size: 28px;
                    font-weight: 400;
                }

                .content {
                    font-size: 22px;
                }

                .content:last-child {
                    margin-bottom: 65px;
                }
            }
        }
    }

    .topTimesWrap.wap {
        display: none;
    }

    .ecTitle {
        text-align: center;
        font-size: 56px;
        font-weight: bold;
        margin-top: 248px;
        position: relative;

        &::after {
            content: 'Rewards Program';
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #a5a5a5;
            font-weight: bold;
            opacity: 0.11;
            font-size: 120px;
            line-height: 168px;
        }
    }
    .ecWrap {
        padding: 154px 150px 182px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 1.5;

        .ecContent {
            width: 30%;
            background: #fff;
            margin-bottom: 50px;
            border-radius: 30px;

            &:nth-child(2) {
                background: #f2f8fd;
            }
            .contentImg {
                text-align: left;
                margin: 72px 0 30px 53px;
                img {
                    width: 70px;
                    height: 70px;
                }
            }

            .contentTitle {
                margin: 40px 0 43px;
                // line-height: 44px;
                font-weight: bold;
                font-size: 40px;
                padding: 0 53px;
            }

            .contentContent {
                // line-height: 44px;
                font-size: 24px;
                font-weight: 500;
                padding: 0 53px 30px;
            }
        }
    }

    .nodeCreate {
        background: url(../assets/images/nodeCreateBg.png) no-repeat;
        background-size: cover;
        height: 682px;
        position: relative;

        &::after {
            content: 'Node creation';
            background-color: rgba(0, 0, 0, 0.35);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 120px;
            color: rgba(255, 255, 255, 0.18);
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
            padding-top: 153px;
        }
        .nctitle {
            font-size: 56px;
            color: #fff;
            font-weight: 500;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        .tabsWrap {
            position: absolute;
            bottom: 0;
            width: calc(100% - 300px);
            left: 150px;
            display: flex;
            color: #fff;
            font-size: 38px;
            font-weight: 400;
            justify-content: space-between;
            z-index: 1;

            .tabs {
                border-bottom: 16px solid transparent;
                padding-bottom: 12px;
                cursor: pointer;

                &.active {
                    border-bottom: 5px solid #1e5bff;
                }
            }
        }
    }

    .tabview {
        background: #2a3246;
        width: calc(100% - 300px);
        padding: 0 150px;

        .ai1Wrap {
            display: flex;
            justify-content: space-between;
            padding: 188px 0 208px;

            & > div {
                width: 740px;
                // padding: 20px;
                background: #fff;
                border-radius: 20px;

                .ai1Bg {
                    background: url(../assets/images/networkConfigBg.png)
                        no-repeat;
                    background-size: cover;
                    height: 400px;
                    position: relative;

                    .icon {
                        width: 180px;
                        height: 180px;
                        border-radius: 50%;
                        background: rgb(66, 83, 125);
                        position: absolute;
                        top: 110px;
                        left: 280px;

                        & > div {
                            background: url(../assets/images/networkConfigIcon.png)
                                no-repeat;
                            background-size: cover;
                            width: 110px;
                            height: 110px;
                            margin: 35px auto;
                        }
                    }
                }

                .title {
                    height: 170px;
                    text-align: center;
                    line-height: 170px;
                    font-size: 40px;
                    font-weight: 400;
                }

                &:last-child .ai1Bg {
                    background: url(../assets/images/bondBg.png) no-repeat;
                    background-size: cover;

                    .icon > div {
                        background: url(../assets/images/bondIcon.png) no-repeat;
                        background-size: cover;
                    }
                }
            }
        }

        .ai2Wrap {
            position: relative;
            .ai2Flex {
                display: flex;
                justify-content: space-between;
                padding: 188px 0 408px;

                & > div {
                    width: 260px;
                    min-height: 230px;
                    padding-bottom: 30px;
                    background: #151e2d;
                    border-radius: 20px;
                    position: relative;

                    .ai2Bg {
                        width: 260px;
                        // height: 260px;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;

                        img {
                            width: 74px;
                            height: 74px;
                            margin-top: 61px;
                        }

                        .title {
                            color: #fff;
                            font-size: 28px;
                            padding: 0 46px;
                            margin-top: 45px;
                        }
                    }

                    .ai2Index0 {
                        color: #fff;

                        .title {
                            margin: 70px 0 24px;
                        }

                        .content {
                            font-size: 24px;
                            padding: 0 46px;
                        }
                    }

                    &:last-child .title {
                        margin-top: 25px !important;
                    }

                    .indexText {
                        background: #1e5bff;
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                        border-radius: 50%;
                        font-size: 30px;
                        color: #fff;
                        text-align: center;
                        position: absolute;
                        bottom: -130px;
                        left: 50%;
                        margin-left: -35px;
                        z-index: 2;
                    }
                }
            }

            .indexLine {
                position: absolute;
                width: calc(100% - 400px);
                height: 2px;
                background: #1e5bff;
                bottom: 313px;
                left: 200px;
            }
        }

        .ai3Wrap {
            position: relative;
            .ai3Flex {
                display: flex;
                justify-content: space-between;
                padding: 188px 0 408px;

                & > div {
                    width: 420px;
                    min-height: 250px;
                    padding-bottom: 30px;
                    background: #151e2d;
                    border-radius: 20px;
                    position: relative;

                    .ai3Bg {
                        width: 420px;
                        // height: 260px;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;

                        img {
                            width: 74px;
                            height: 74px;
                            margin-top: 61px;
                        }

                        .title {
                            color: #fff;
                            font-size: 28px;
                            padding: 0 46px;
                            margin-top: 45px;
                        }
                    }

                    &:last-child .title {
                        margin-top: 25px !important;
                    }

                    .indexText {
                        background: #1e5bff;
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                        border-radius: 50%;
                        font-size: 30px;
                        color: #fff;
                        text-align: center;
                        position: absolute;
                        bottom: -130px;
                        left: 50%;
                        margin-left: -35px;
                        z-index: 2;
                    }
                }
            }

            .indexLine {
                position: absolute;
                width: calc(100% - 400px);
                height: 2px;
                background: #1e5bff;
                bottom: 313px;
                left: 200px;
            }
        }
    }

    .ec-dialog {
        width: 740px;
        padding: 43px 70px 0;
        box-sizing: border-box;
        backdrop-filter: blur(20px) brightness(130%);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        color: white;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ec-dialog-title {
            font-size: 28px;
            text-align: center;
            margin-bottom: 34px;
        }

        .ec-form {
            width: 100%;

            label.ec-form-item {
                display: flex;
                min-width: 100%;
                background-color: white;
                border-radius: 10px;
                padding: 0 20px;
                box-sizing: border-box;
                align-items: center;
                margin-bottom: 20px;
                cursor: text;
                border: 1px solid transparent;
                font-size: 16px;

                &.error {
                    border-color: #f11;
                }

                span {
                    color: #f11;
                    margin-right: 20px;
                    user-select: none;
                }

                input {
                    flex: 1;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    line-height: 58px;
                    font-size: 18px;
                    padding: 0;
                }
            }
        }

        .ec-form--button {
            padding: 15px 56px;
            font-size: 20px;
            border-radius: 10px;
            background-color: #1882ff;
            margin-top: 10px;
            margin-bottom: 15px;
            user-select: none;
            cursor: pointer;
        }

        .ec-dialog--info {
            padding-inline-start: 20px;
            margin-bottom: 22px;

            li {
                list-style-type: disc;
                font-size: 16px;
            }
        }

        .thrid-service {
            font-size: 15px;
            padding: 10px 20px;
            background-color: #1b4592;
            border-radius: 10px 10px 0px 0px;
        }
    }

    @media only screen and (max-width: 990px) {
        .advantageWrap {
            background: url(../assets/images/recruitmentBg.png) no-repeat;
            background-size: cover;
            background-position: bottom;
            height: auto;
            color: #fff;
            position: relative;
            padding-bottom: 120px;

            .advantage {
                display: flex;
                flex-direction: column;
                width: calc(100% - 300px);
                height: auto;
                margin: 0 150px;
                align-items: start;
                justify-content: start;

                .advLeft {
                    .title {
                        font-size: 100px;
                        font-weight: bold;
                        margin: 186px 0;
                    }

                    .btns {
                        display: flex;
                        justify-content: start;
                        & > div {
                            background: #6a84c3;
                            margin-right: 90px;
                            padding: 18px 50px;
                            border-radius: 10px;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                }

                .advRight {
                    background: rgba(41, 182, 255, 0.4);
                    backdrop-filter: blur(20px) brightness(130%);
                    box-shadow: none;
                    padding: 90px 55px 90px;
                    width: 90%;
                    margin-top: 200px;

                    .title {
                        font-size: 76px;
                    }

                    .advContent {
                        font-size: 60px;
                    }

                    .line {
                        width: 100%;
                        height: 2px;
                        background: #fff;
                        margin: 30px 0;
                    }
                }
            }

            .topTimesWrap.pc {
                display: none !important;
            }
        }

        .topTimesWrap {
            display: block !important;
            background: #0a2c71;
            background-size: 100% 100%;
            padding: 0 5%;
            width: 90%;
            color: #fff;

            .timeWrap {
                width: 100%;
                text-align: center;
                position: relative;
                z-index: 1;
                border-bottom: 1px solid #fff;

                .title {
                    font-size: 78px;
                    font-weight: 400;
                    // background: red;
                    display: inline-block;
                    padding: 38px 103px;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                    margin-bottom: 38px;
                    position: relative;
                    overflow: hidden;
                    backdrop-filter: blur(20px) brightness(130%);
                    box-shadow: none;
                }

                .time {
                    min-height: 175px;
                    font-size: 68px;
                    font-weight: 400;
                }

                .content {
                    font-size: 62px;
                }

                .content:last-child {
                    margin-bottom: 100px;
                }
            }

            & .timeWrap:last-child {
                border: none !important;
            }
        }

        .ecTitle {
            text-align: center;
            font-size: 100px;
            font-weight: bold;
            margin-top: 248px;
            position: relative;

            &::after {
                content: 'Rewards Program';
                width: 100%;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                color: #a5a5a5;
                font-weight: bold;
                opacity: 0.11;
                font-size: 120px;
                line-height: 168px;
            }
        }

        .ecWrap {
            padding: 154px 150px 182px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            line-height: 1.5;

            .ecContent {
                width: 100%;
                background: #fff;
                margin-bottom: 50px;
                border-radius: 30px;

                &:nth-child(2) {
                    background: #f2f8fd;
                }
                .contentImg {
                    text-align: left;
                    margin: 22px 0 0px 53px;
                    img {
                        width: 140px;
                        height: 140px;
                    }
                }

                .contentTitle {
                    margin: 40px 0 43px;
                    // line-height: 44px;
                    font-weight: bold;
                    font-size: 78px;
                    padding: 0 53px;
                }

                .contentContent {
                    // line-height: 44px;
                    font-size: 64px;
                    font-weight: 500;
                    padding: 0 53px 30px;
                }
            }
        }

        .nodeCreate {
            background: url(../assets/images/nodeCreateBg.png) no-repeat;
            background-size: cover;
            height: 682px;
            position: relative;

            &::after {
                content: 'Node creation';
                background-color: rgba(0, 0, 0, 0.35);
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 120px;
                color: rgba(255, 255, 255, 0.18);
                font-weight: bold;
                text-align: center;
                box-sizing: border-box;
                padding-top: 153px;
            }
            .nctitle {
                font-size: 96px;
                color: #fff;
                font-weight: 500;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }

            .tabsWrap {
                position: absolute;
                bottom: 0;
                width: calc(100% - 300px);
                left: 150px;
                display: flex;
                color: #fff;
                font-size: 38px;
                font-weight: 400;
                justify-content: space-between;
                z-index: 1;

                .tabs {
                    border-bottom: 16px solid transparent;
                    padding-bottom: 12px;
                    cursor: pointer;

                    &.active {
                        border-bottom: 5px solid #1e5bff;
                    }
                }
            }
        }

        .tabview {
            background: #2a3246;
            width: calc(100% - 300px);
            padding: 0 150px;

            .ai1Wrap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 188px 0 208px;

                & > div {
                    width: 90%;
                    margin-left: 5%;
                    // padding: 20px;
                    background: #fff;
                    border-radius: 30px;
                    margin-bottom: 120px;

                    .ai1Bg {
                        background: url(../assets/images/networkConfigBg.png)
                            no-repeat;
                        background-size: cover;
                        height: 600px;
                        position: relative;

                        .icon {
                            width: 300px;
                            height: 300px;
                            border-radius: 50%;
                            background: rgb(66, 83, 125);
                            position: absolute;
                            top: 150px;
                            left: 50%;
                            margin-left: -180px;

                            & > div {
                                background: url(../assets/images/networkConfigIcon.png)
                                    no-repeat;
                                background-size: cover;
                                width: 110px;
                                height: 110px;
                                margin: 95px auto;
                            }
                        }
                    }

                    .title {
                        height: 210px;
                        text-align: center;
                        line-height: 210px;
                        font-size: 60px;
                        font-weight: 400;
                    }

                    &:last-child .ai1Bg {
                        background: url(../assets/images/bondBg.png) no-repeat;
                        background-size: cover;

                        .icon > div {
                            width: 110px;
                            height: 110px;
                            background: url(../assets/images/bondIcon.png)
                                no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }

            .ai2Wrap {
                position: relative;
                .ai2Flex {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 188px 0 408px;

                    & > div {
                        width: 100%;
                        margin: 30px 0;
                        min-height: 230px;
                        padding-bottom: 50px;
                        background: #151e2d;
                        border-radius: 30px;
                        position: relative;

                        .ai2Bg {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            // justify-content: center;
                            align-items: center;

                            img {
                                width: 204px;
                                height: 204px;
                                margin-top: 61px;
                            }

                            .title {
                                color: #fff;
                                font-size: 88px;
                                padding: 0 76px;
                                margin-top: 45px;
                            }
                        }

                        .ai2Index0 {
                            color: #fff;

                            .title {
                                margin: 70px 0 24px;
                            }

                            .content {
                                font-size: 74px;
                                padding: 0 46px;
                            }
                        }

                        &:last-child .title {
                            margin-top: 25px !important;
                        }

                        .indexText {
                            display: none !important;
                        }
                    }
                }

                .indexLine {
                    display: none !important;
                }
            }

            .ai3Wrap {
                position: relative;
                .ai3Flex {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 188px 0 408px;

                    & > div {
                        width: 100%;
                        margin: 30px 0;
                        min-height: 230px;
                        padding-bottom: 50px;
                        background: #151e2d;
                        border-radius: 30px;
                        position: relative;

                        .ai3Bg {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            // justify-content: center;
                            align-items: center;

                            img {
                                width: 204px;
                                height: 204px;
                                margin-top: 61px;
                            }

                            .title {
                                color: #fff;
                                font-size: 88px;
                                padding: 0 76px;
                                margin-top: 45px;
                            }
                        }

                        &:last-child .title {
                            margin-top: 25px !important;
                        }

                        .indexText {
                            display: none !important;
                        }
                    }
                }

                .indexLine {
                    display: none !important;
                }
            }
        }

        .ec-dialog {
            width: 1800px;

            .ec-dialog-title {
                font-size: 120px;
            }

            .ec-form {
                label.ec-form-item {
                    font-size: 80px;

                    input {
                        font-size: 80px;
                        line-height: 2.5;
                    }
                }
            }

            .ec-form--button {
                font-size: 80px;
            }

            .ec-dialog--info {
                padding-left: 120px;
            }
        }
    }*/
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .ecological {
        .advantage {
            background: url(../assets/images/m-bg4.png) no-repeat center top;
            background-size: 7.5rem 7.85rem;
            height: 7.85rem;

            .w {
                width: 6.1rem;

                .title {
                    width: 100%;
                    font-size: 0.45rem;
                    top: 0.79rem;
                    left: -0.1rem;

                    b {
                        top: 0.5rem;
                    }
                }

                .adv-info {
                    font-size: 0.24rem;
                    padding: 0;
                    top: 2.2rem;
                    width: 3.07rem;
                    left: -0.1rem;
                    line-height: 0.34rem;
                    color: #777E90;
                }
            }
        }

        .node{
            width: 100%;
            height: 17rem;
            .w{
                width: 6.98rem;
                .why{
                    width: 5rem;
                    margin: 0.52rem auto 0.2rem;

                    h2{
                        font-size: 0.35rem;
                    }

                    em{
                        width: 0.52rem;
                        margin: 0.2rem auto;
                    }
                    h5{
                        font-size: 0.21rem;
                    }
                }

                .adv-child{
                    margin-top: 0.22rem;
                    box-sizing: border-box;
                    width: 6.98rem;
                    height: 5rem;
                    padding: 0.26rem;
                    border-radius: 0.28rem;

                    .line{
                        width: 1.04rem;
                        height: 1.04rem;
                        margin-top: -0.5rem;
                        margin-left: -0.14rem;

                        img{
                            width: 1.04rem;
                            height: 1.04rem;
                        }
                    }

                    .title1{
                        font-size: 0.27rem;
                        margin: 0.27rem 0;
                    }

                    .content{
                        font-size: 0.24rem;
                        width: 6.03rem;
                        line-height: 0.38rem;
                    }
                }

                .adv-child:nth-child(3){
                    margin: 0.22rem 0 0;
                    height: 4rem;
                }
            }
        }
    }
}
</style>
