<!-- 技术亮点 -->
<template>
    <div class="home">
        <div class="advantage">
           <div class="w">
                <div class="title">
                    {{ "ICWTechLight1" | t }} <br /><b>{{ "ICWTechLight2" | t }}</b>
                </div>
                <div class="line"></div>
               <div class="adv-info">
                    {{ "ICWTechLightSub" | t }}
                </div>
               <div class="pdf">
                    <a @click="downloadPDF('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-en.pdf')"
                        target="_blank">
                        {{ "ICWwhitePaper" | t }}
                    </a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="w">
                <!-- 左侧 -->
                <div class="sideBar fl">
                    <div class="sideTitle">{{ "techIntroduction" | t }}</div>
                   <div class="titles">
                        <div class="firstTit"><span class="dot"></span>{{ "technologyFunctions" | t }}</div>
                        <a class="title"><span class="secondTitle">{{
                            "decentralizationTitle" | t }}</span>
                        </a>
                        <a class="title"><span class="secondTitle">{{
                            "contractTitle" |
                            t }}</span>
                        </a>
                        <div class="roadmap">
                            <span class="dot"></span>
                            {{ "roadmap" | t }}
                        </div>
                    </div>
                </div>
                <!-- 右侧 -->
             <div class="contant fl">
                    <h2>{{ "technologyFunctions" | t }}</h2>
                   <div class="text1" id="Decentralization">
                        <p class="textT">{{ "decentralizationTitle1" | t }} <b>{{ "decentralizationTitle2" | t }}</b></p>
                        <div class="textW">
                            <div class="bold">
                                <span class="dot"></span>
                                {{ "decentralizationSubContent1" | t }}
                            </div>
                            <div class="bold">
                                <span class="dot"></span>
                                {{ "decentralizationSubContent5" | t }}
                            </div>
                            <div class="bold">
                                <span class="dot"></span>
                                {{ "decentralizationSubContent3" | t }}
                            </div>
                        </div>
                    </div>
                   <div class="text2" id="General">
                        <p class="textT">{{ "contractTitle" | t }}</p>
                        <div class="textW">
                            <div class="light">
                                {{ "contractSubContent1" | t }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div class="timeInfo" id="Road">
           <div class="line">
               <div class="w">
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                </div>
            </div>
            <div class="photo"></div>
           <div class="w">
                <div class="timeTitle">
                    {{ "map" | t }}
                </div>
               <div class="timeLine">
                    <div v-for="item in dataList" :key="item.id">
                        <h1 class="year">{{ item.time }}</h1>
                        <p class="info">
                            {{ item.content | t }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import downloadjs from 'downloadjs'

export default {
    name: 'technologyPage',
    data() {
        return {
            contentStyleObj: {
                height: '',
                width: ''
            },
            GetWindowHeight: {
                height: ''
            },
            dataList: [
                {
                    id: 1,
                    time: '2023 Q1',
                    content: 'techRoadMap1'
                },
                {
                    id: 2,
                    time: '2023 Q2',
                    content: 'techRoadMap2'
                },
                {
                    id: 3,
                    time: '2023 Q3',
                    content: 'techRoadMap3'
                },
                {
                    id: 4,
                    time: '2023 Q4',
                    content: 'techRoadMap4'
                },
                {
                    id: 5,
                    time: '2024 Q1',
                    content: 'techRoadMap5'
                }, {
                    id: 6,
                    time: '2024 Q2',
                    content: 'techRoadMap6'
                }
            ]
        }
    },
    created() {
        window.addEventListener('resize', this.getHeight)
        this.getHeight()
    },

    destroyed() {
        window.removeEventListener('resize', this.getHeight)
    },
    methods: {
        getHeight() {
            // 获取浏览器高度
            this.contentStyleObj.width = window.innerWidth + 'px'
            this.contentStyleObj.height = window.innerHeight + 'px'
            // 获取浏览器高度，减去顶部导航栏的值70（可动态获取）,再减去head-DIV高度值80
            this.GetWindowHeight.height = window.innerHeight - 200 + 'px'
        },
        gomd(id) {
            // 点击题号跳
            this.$el.querySelector('#' + id).scrollIntoView()
        },
        clickgo(id) {
            // 点击题号跳转
            document.querySelector('#' + id).scrollIntoView()
        },
        downloadPDF(url) {
            // const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            // const pdfUrl = 'http://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(url)
        }
    }
}
</script>
<style lang="less" scoped>
.home {
    .advantage {
        font-family: 'Poppins';
        width: 100%;
        height: 717px;
        background: url('../assets/images/bg2.png') no-repeat center;
        margin: 0 auto;

        .w {
            position: relative;
        }

        .title {
            font-family: 'MiSans';
            position: absolute;
            top: 155px;
            left: 84px;
            font-weight: 700;
            font-size: 64px;
            line-height: 56px;
            color: #021829;

            b {
                position: absolute;
                top: 85px;
                color: #246EFF;
            }
        }

        .adv-info {
            position: absolute;
            top: 325px;
            left: 84px;
            width: 758px;
            height: 112px;
            line-height: 28px;
            font-size: 18px;
            color: #777E90;
            font-weight: 500;
        }

        .pdf a {
            position: absolute;
            top: 565px;
            left: 84px;
            width: 260px;
            height: 48px;
            line-height: 48px;
            background-color: #246EFF;
            color: #FCFCFD;
            font-size: 16px;
            text-align: center;
            font-weight: 700;
            cursor: pointer;
            border-radius: 24px;
        }

        .pdf a:hover {
            background: #0043b3;
        }
    }

    a {
        cursor: pointer;
    }

    .container {
        font-family: 'Poppins';
        box-sizing: border-box;
        width: 100%;
        height: 950px;
        padding-top: 50px;

        .sideBar {
            margin-left: 11px;

            .sideTitle {
                width: 329px;
                height: 60px;
                line-height: 60px;
                background-color: #246EFF;
                font-size: 20px;
                color: #FFFFFF;
                text-align: center;
            }

            .titles {
                box-sizing: border-box;
                margin-top: 10px;
                padding: 10px 20px 0;
                width: 329px;
                height: 761px;
                background-color: #F7F9FF;

                .dot {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #246EFF;
                    margin-right: 10px;
                }
            }

            .firstTit {
                margin-top: 20px;
                margin-bottom: 30px;
                font-size: 18px;
                font-weight: 600;
                color: #021829;
            }

            .titles {

                .title {
                    cursor: pointer;
                    display: block;
                    font-size: 24px;
                    font-family: Poppins;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;

                    .dot {
                        display: block;
                        width: 12px;
                        height: 12px;
                        background: #3c5ef1;
                        border-radius: 50%;
                        margin: 5px 20px 0px 0px;
                    }

                    .secondTitle {
                        width: 200px;
                        margin-left: 13px;
                        display: block;
                        margin-bottom: 20px;
                        font-size: 14px;
                        color: #021829;
                    }
                }
            }

            .roadmap {
                font-size: 15px;
                font-weight: 600;
                color: #021829;
            }
        }

        .contant {
            box-sizing: border-box;
            width: 830px;
            height: 560px;
            margin-left: 30px;

            h2 {
                height: 45px;
                line-height: 45px;
                font-size: 28px;
                color: #021829;
                font-weight: 600;
            }

            .text1 {
                box-sizing: border-box;
                width: 830px;
                height: 363px;
                background-color: #F7F9FF;
                margin-top: 25px;
                padding: 45px 0 0 35px;

                .textT {
                    height: 45px;
                    line-height: 45px;
                    color: #246EFF;
                    font-size: 28px;
                    font-weight: 700;

                    b{
                        color: #424242;
                    }
                }

                .textW {
                    margin-top: 20px;

                    .light {
                        width: 740px;
                        font-size: 24px;
                        color: #94A5C5;
                    }

                    .bold {
                        height: 50px;
                        line-height: 50px;
                        font-size: 20px;
                        color: #94A5C5;
                        margin-bottom: 20px;

                        .dot {
                            display: inline-block;
                            background-color: #246EFF;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            margin-right: 25px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }

            .text2 {
                margin-top: 27px;
                box-sizing: border-box;
                width: 830px;
                height: 370px;
                background-color: #F7F9FF;
                padding: 45px 0 0 35px;

                .textT {
                    height: 45px;
                    line-height: 45px;
                    color: #246EFF;
                    font-size: 28px;
                    font-weight: 700;
                }

                .textW {
                    margin-top: 20px;

                    .light {
                        width: 760px;
                        font-size: 20px;
                        line-height: 40px;
                        color: #94A5C5;
                    }
                }
            }
        }

    }

    .timeInfo {
        font-family: 'Poppins';
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 763px;
        background-color: #F7F9FF;
        padding-top: 70px;
        .photo{
          display: none;
        }

        .timeTitle {
            box-sizing: border-box;
            width: 250px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            margin: 0 auto;
            background-color: #DFEAFF;
            border-radius: 30px;
            font-size: 28px;
            color: #021829;
            font-weight: 500;
        }

        .timeLine {
            margin-top: 45px;
            display: flex;
            flex-wrap: wrap;

            div:nth-child(n+4){
                text-align: right;
            }

            div {
                box-sizing: border-box;
                width: 300px;
                height: 160px;
                padding: 20px;
                background-color: #FFFFFF;
                border-radius: 16px;
                margin: 45px 150px 45px 0;
            }

            div:nth-child(3),
            div:nth-child(6){
                margin-right: 0;
            }

            .year {
                width: 250px;
                height: 28px;
                line-height: 28px;
                font-size: 24px;
                color: #021829;

            }

            .info {
                text-align: left;
                width: 250px;
                margin-top: 10px;
                font-size: 16px;
                color: #94A5C5;
            }
        }

        .line {
            position: absolute;
            bottom: 340px;
            width: 100%;
            height: 8px;
            background-color: #246EFF;
            border-radius: 8px;

            .w {
                position: relative;
            }

            .active {
                position: absolute;
                top: -10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #FFFFFF;
                border: 4px solid #246EFF;
            }

            .active:nth-child(odd) {
                border: 4px solid #6B9BFF;
            }

            .active:first-child {
                left: -10px;
            }

            .active:nth-child(2) {
                left: 360px;
            }

            .active:nth-child(3) {
                left: 520px;
            }

            .active:nth-child(4) {
                left: 900px;
            }

            .active:nth-child(5) {
                left: 1050px;
            }

            .active:nth-child(6) {
                left: 1200px;
            }
        }
    }
}
</style>
<!-- 移动端 -->
<style lang="less" scoped>
  @media only screen and (max-width: 990px) {
    .home{
       .advantage{
         background: url('../assets/images/shift-bg2.png') no-repeat center;
         background-size: 7.5rem  8.32rem;
         height: 8.32rem;
       }
       .w{
         width: 6.1rem;
         margin: 0 auto;
         .title {
           width: 6.31rem;
           font-size: 0.45rem;
           top: 0.85rem;
           left: -0.1rem;
           b{
             width: 3.4rem;
             top: 0.6rem;
           }
         }
         .adv-info{
          width: 3.22rem;
          height: 1.95rem;
          line-height: 0.38rem;
          font-size: 0.24rem;
          top: 2.27rem;
          left: -0.1rem;
          color: #777E90;
          font-weight:0;
         }
         .pdf a {
             position: absolute;
             top: 7.32rem;
             left: -0.1rem;
             width: 3.3rem;
             height: 0.55rem;
             line-height: 0.53rem;
             background-color: #FFFFFF;
             color: #246EFF;
             font-size: 0.13rem;
             text-align: center;
             font-weight: 0;
             cursor: pointer;
             border: 0.01rem solid #246EFF;
         }
       }
       .container{
         width: 100%;
         height: 10rem;
         padding-top: 0rem;
         .w{
           width: 6.98rem;
           .sideBar{
             margin-left: 0px;
             float:none;
             .sideTitle{
               width: 3.78rem;
               height: 0.73rem;
               margin: 0 auto;
               line-height: 0.73rem;
               background-color: #ffffff;
               font-size: 0.26rem;
               color: #246EFF;
             }
             .titles{
               display: none;
             }
           }
           .contant{
             float:none;
             box-sizing: border-box;
             width: 6.98rem;
             height: 0.17rem;
             margin: 0 auto;
             h2 {
                 height: 0.17rem;
                 line-height: 0.17rem;
                 font-size: 0.2rem;
                 color: #94A5C5;
                 font-weight: normal;
                 text-align: center;
             }
             .text1{
               width: 6.98rem;
               height: 4rem;
               background-color: #F7F9FF;
               margin-top: 0.3rem;
               padding: 0.45rem 0 0 0;
               border-radius: 0.2rem;
               .textT{
                 height: 0.17rem;
                 line-height: 0.17rem;
                 font-weight: 12.21rem;
                 font-size: 0.22rem;
                 text-align: center;
               }
               .textW{
                 margin-top: 0.62rem;
                 padding: 0 0.24rem;
                 .bold{
                   height: 0.4rem;
                   line-height: 0.4rem;
                   font-size: 0.19rem;
                   color: #94A5C5;
                   margin-bottom: 0.45rem;
                 }
               }
             }
             .text2{
               width: 6.98rem;
               height: 4rem;
               background-color: #F7F9FF;
               margin-top: 0.3rem;
               padding: 0.45rem 0 0 0;
               border-radius: 0.2rem;
               .textT{
                 height: 0.17rem;
                 line-height: 0.17rem;
                 font-weight: 12.21rem;
                 font-size: 0.22rem;
                 text-align: center;
               }
               .textW{
                 margin-top: 0.62rem;
                 padding: 0 0.24rem;
                 .light{
                   width:6.5rem;
                   height: 0.4rem;
                   line-height: 0.4rem;
                   font-size: 0.19rem;
                   color: #94A5C5;
                   margin-bottom: 0.45rem;
                 }
               }
             }
           }
         }
       }
       .timeInfo{
         font-family: 'Poppins';
         position: relative;
         box-sizing: border-box;
         width: 100%;
         height: 15.33rem;
         background-color:#F7F9FF;
         padding-top: 0.73rem;
         display: flex;
         .line {
           display: none;
         }
         .photo{
           height: 12.22rem;
           width: 0.17rem;
           margin-left: 0.62rem;
           margin-top: 1.25rem;
           background: url('../assets/images/StepCounting.png') no-repeat;
           background-size: 0.17rem  12.22rem;
           display: block;
         }
         .timeTitle {
             box-sizing: border-box;
             width: 2.6rem;
             height: 0.48rem;
             line-height: 0.48rem;
             text-align: center;
             margin: 0 auto;
             background-color: #DFEAFF;
             border-radius: 0.48rem;
             font-size: 0.26rem;
             color: #021829;
             font-weight: 8.72rem;
         }
        .timeLine {
            margin-top: 0.26rem;
            display: flex;
            flex-wrap: wrap;

            div:nth-child(n+4){
                text-align: left;
            }

            div {
                box-sizing: border-box;
                width: 5.49rem;
                height: 1.6rem;
                padding: 0.17rem;
                background-color: #FFFFFF;
                border-radius: 0.2rem;
                margin: 0 0.52rem 0.7rem 0;
            }

            div:nth-child(3),
            div:nth-child(6){
                margin-right: 0;
            }

            .year {
                width: 1.43rem;
                height: 0.48rem;
                line-height: 0.48rem;
                font-size: 0.31rem;
                font-weight: 8.72rem;
                color: #021829;

            }

            .info {
                text-align: left;
                width: 5.1rem;
                margin-top: 0.1rem;
                font-size: 0.20rem;
                color:#94A5C5;
            }
        }
       }
    }
  }
</style>
