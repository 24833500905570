<!-- 生态 -->
<template>
  <div class="ecological">
    <div class="advantage">
     <div class="w">
        <div class="title">
          {{ "ecologicalNetTit1" | t }} <br>
          <b>{{ "ecologicalNetTit2" | t }}</b>
        </div>
        <div class="adv-info">
          {{ "ecologicalSub1" | t }} <br>
          {{ "ecologicalSub2" | t }}
        </div>
      </div>
    </div>
   <div class="ec-main">
      <div class="w">
        <div class="ecTitle">
          <h2>{{ "Find1" | t }} <b>{{ "Find2" | t }}</b></h2>
          <em></em>
          <h5>{{ "development" | t }}</h5>
        </div>
       <div class="ecWrap">

          <ul class="ecWrapList">
            <li v-on:click='change(index)' :class='currentIndex==index?"active":""' :key='item.id' v-for='(item,index) in ecWrapList'>{{item.title}}</li>

          </ul>
        <div class="ecContent">
            <ul  :class='currentIndex==index?"current":""' :key='item.id' v-for='(item, index) in ecWrapList'>
                <a :href="item.chirlden.href1"><img :src="item.chirlden.path1" alt=""></a>
              <span v-if="item.chirlden.path2">
                <!-- <a v-if="item.chirlden.href2 =='BitHive'" @click="downloadPDF('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/BitHive%20Wallet.zip')"><img :src="item.chirlden.path2" alt=""></a> -->
                <a  :href="item.chirlden.href2"><img :src="item.chirlden.path2" alt=""></a>
              </span>
              <span v-if="item.chirlden.path3">
                <a  :href="item.chirlden.href3"><img :src="item.chirlden.path3" alt=""></a>
              </span>
          </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import downloadjs from 'downloadjs'
import explor1 from '@/assets/images/explor2.png'
// import explor2 from '@/assets/images/explor2.png'
// import cex1 from '@/assets/images/CEX1.png'
// import cex2 from '@/assets/images/CEX2.png'
import fund1 from '@/assets/images/fund2.png'
import wallet1 from '@/assets/images/wallet2.png'
import BitHive from '@/assets/images/BitHive.png'
import swap from '@/assets/images/swap.png'
import android from '@/assets/images/android.png'
import cos1 from '@/assets/images/COS1.png'
import { getBlockheight } from '@/utils/api'

export default {
    name: 'ecological-page',
    data() {
        return {
            currentIndex: 0,
            ecWrapList: [{
                id: 1,
                title: 'TOOLS',
                chirlden: {
                    path1: explor1,
                    href1: 'https://browser.icwchain.com/'
                }
            },
            {
                id: 2,
                title: 'WALLETS',
                chirlden: {
                    path1: wallet1,
                    href1: 'https://wallet.icwchain.com/',
                    path2: BitHive,
                    href2: 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/BitHive%20Wallet.zip',
                    path3: android,
                    href3: ''
                }
            }, {
                id: 3,
                title: 'DEFI',
                chirlden: {
                    path1: fund1,
                    href1: 'http://icwfund.icwchain.com/#/home?lang=en'
                }
            }, {
                id: 4,
                title: 'GAMEFI',
                chirlden: { path1: cos1 }
            }, {
                id: 5,
                title: 'DEX',
                chirlden: {
                    path1: swap,
                    href1: 'http://icw.antswap.co'
                }
            }],
            Download: ''
            // {
            //     id: 6,
            //     title: 'CEX',
            //     chirlden: {
            //         path1: cex1,
            //         href1: 'https://www.bifinance.com/',
            //         path2: cex2,
            //         href2: 'https://www.cgxyex.com'
            //     }
            // }]
        }
    },
    created () {
        this.getBlockheights()
    },
    methods: {
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        // downloadPDF(url) {
        //     // const lang = this.$store.getters.getLanguage
        //     // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
        //     // const pdfUrl = 'http://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
        //     downloadjs(url)
        // },
        change: function(index) {
            this.currentIndex = index
        },
        async getBlockheights() {
            this.randomNumbers = Math.round(Math.random() * 9999)
            const { data: res } = await getBlockheight()
            console.log('res', res)
            this.Download = res.data
            this.ecWrapList[1].chirlden.href3 = this.Download
        }
    }
}
</script>
<style lang="less" scoped>
.ecological {
  .advantage {
    font-family: 'MiSans';
    background: url("../assets/images/bg3.png") no-repeat center top;
    width: 100%;
    height: 717px;
    margin: 0 auto;

    .w {
      position: relative;
    }

    .title {
      position: absolute;
      top: 155px;
      left: 84px;
      font-weight: 700;
      font-size: 64px;
      color: #021829;

      b {
        position: absolute;
        top: 85px;
        color: #246EFF;
      }
    }

    .adv-info {
      font-family: 'Poppins';
      position: absolute;
      top: 355px;
      left: 84px;
      width: 758px;
      height: 84px;
      font-size: 16px;
      line-height: 28px;
      color: #777E90;
    }
  }

  .ec-main {
    width: 100%;
    height: 806px;
  }

  .ecTitle {
    font-family: 'Poppins';
    color: #081022;
    width: 654px;
    text-align: center;
    margin: 0 auto;
    padding-top: 100px;

    h2 {
      font-size: 36px;
    }

    b {
      color: #246EFF;
    }

    em {
      display: block;
      width: 39px;
      height: 1px;
      margin: 25px auto;
      text-align: center;
      background-color: #94A5C5;
    }

    h5 {
    font-weight: normal;
    color: #94A5C5;
    font-size: 16px;
}
  }

  .ecWrap {
    font-family: 'Poppins';
    margin-top: 120px;
    text-align: center;

    li {
      display: inline-block;
      height: 44px;
      line-height: 44px;
      padding: 0 40px;
      margin-right: 15px;
      font-size: 24px;
      color: #A7AAB6;
      border-radius: 30px;
      cursor: pointer;
    }

    .active {
      background-color: #246EFF;
      color: #FFFFFF;
    }

    .ecContent {
      margin-top: 100px;

      ul{
        display: none;
      }
      // ul:nth-child(2){
      //   padding-left: 10px;
      // }

      .current{
        display: block;
        text-align: center;
        // padding-left: 350px;
      }

      a {
        padding-left: 10px;
        // float: left;
        // margin-right: 20px;
        // display: block;
        cursor: pointer;

 img{
          width: 360px;
        height: 158px;

        }
        img[src=""],img:not([src]){
 opacity: 0;
}
      }
    }
  }
}
</style>
<!-- 移动端 -->
<style lang="less" scoped>
  @media only screen and (max-width: 990px) {
  .ecological{
    .advantage{
      background: url('../assets/images/shift-bg3.png') no-repeat center;
      background-size: 7.5rem  6.64rem;
      height: 6.64rem;
    }
    .w{
      width: 6.1rem;
      margin: 0 auto;
      .title {
        width: 6.31rem;
        font-size: 0.45rem;
        top: 0.85rem;
        left: -0.1rem;
        b{
          width: 3.4rem;
          top: 0.6rem;
        }
      }
      .adv-info{
       width: 3.22rem;
       height: 3.15rem;
       line-height: 0.38rem;
       font-size: 0.24rem;
       top: 2.27rem;
       left: -0.1rem;
       color: #777E90;
       font-weight:normal;
      }
    }
    .ec-main{
      width: 100%;
      height: 5.83rem;
      .w{
        width: 7.22rem;
        .ecTitle {
            font-family: 'Poppins';
            color: #081022;
            width: 5.37rem;
            text-align: center;
            margin: 0 auto;
            padding-top: 0.40rem;
            h2 {
                  font-size: 0.26rem;
                }
                em {
                  display: block;
                  width: 0.52rem;
                  height: 0.01rem;
                  margin: 0.2rem auto;
                  text-align: center;
                  background-color: #94A5C5;
                }
                h5 {
                font-weight: normal;
                color: #94A5C5;
                font-size: 0.2rem;
            }
          }
          .ecWrap{
            margin-top: 0.15rem;
            text-align: center;
              li {
                display: inline-block;
                width: 1.09rem;
                height: 0.43rem;
                line-height: 0.43rem;
                padding: 0 0.05rem;
                margin-right: 0;
                font-size: 0.2rem;
                color: #A7AAB6;
                border-radius: 0.2rem;
                cursor: pointer;
              }
              .ecContent {
                margin-top: 0.4rem;

                ul{
                  display: none;
                }

                .current{
                  display: block;
                  text-align: center;
                  // padding-left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                a {
                  width: 3.31rem;
                  height: 2.09rem;
                  // float: left;
                  margin:0 0.12rem;
                  cursor: pointer;
                  img{
                   width: 3.31rem;
                   height: 2.09rem;
                   background: #ffffff;
                   border-color: none;
                  }
                }
                img:not([src]) {
                    opacity: 0;
                }
              }
          }
      }
    }
  }
  }
</style>
