import Vue from 'vue'
import VueRouter from 'vue-router'
import r from './components'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: r.Home
    },
    {
        path: '/technology',
        name: 'technology',
        component: r.Technology
    },
    {
        path: '/recruitment',
        name: 'recruitment',
        component: r.Recruitment
    },
    {
        path: '/contact',
        name: 'contact',
        component: r.Contact
    },
    {
        path: '/partner',
        name: 'partner',
        component: r.Partner
    },
    {
        path: '/ecological',
        name: 'ecological',
        component: r.Ecological
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
