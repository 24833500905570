export default {
    home: '首页',
    technology: '技术亮点',
    ecological: '生态',
    browser: '区块链浏览器',
    recruitment: '节点',
    wallet: '钱包',
    contact: '联系我们',
    joinUs: '加入我们',
    partner: '合作伙伴',
    language: '语言',
    learnMore: '了解更多',
    company: 'EDAO发展基金会有限公司',
    funds: 'ICW 基金',
    lucky: '幸运转盘',
    hashGuess: '哈希竞猜',
    prize: '福利体量大 百分百中奖',
    prizeBtn: '立即进入',

    aboutICW: '关于ICW',
    whitePaper: '白皮书',
    ICWwhitePaper: 'ICWChain  白皮书',
    technologyFunctions: '技术与功能',
    roadmap: '路线图',
    map: '路线图',

    White: '白皮书',
    Introduction: 'ICW Chain介绍',

    Numbertransactions: '交易数',
    Blockheight: '区块高度',
    Coretoken: '全网委托量',

    welcometoICW: '欢迎访问ICW',
    bannerText: 'ICWChain来源于“I Ching Way”，“Ching”意味着“转换、改变、创新”。ICWChain是一条兼顾去中心化、高性能和数据安全的创新型公有链网络。ICWChain将作为第三代互联网科技革命浪潮中的创新者与领航员，以推动全球数字化经济体系建设为前行动力和终极使命。',
    whitePaperDownload: '白皮书下载链接',
    whychoose: '为什么选择',
    aspires: 'ICW励志做WEB3.0的领航者',
    decentralizationTitle: '去中心化、安全性、高性能',
    decentralizationTitle1: '去中心化、',
    decentralizationTitle2: '安全性、高性能',
    decentralizationSubtitle: 'ICWChain采用了DPOS+PoW混合共识模型，通过提高节点的参与门槛和记账组的随机性提高区块链网络的去中心化程度和社区的公平性。',
    contractTitle: '通用交易智能合约',
    contractSubtitle: 'ICWChain 为链上撮合和交易订单管理扩展了一个完全去中心化的解决方案。',
    highlyModularTitle: '高度模块化',
    highlyModularSubtitle: 'ICWChain引用的NULS模块架构具有热插拔、高效、长期、无时间等特点，大大降低区块链的整体复杂度，简化区块链的设计、调试和维护。',
    crossChainCommunicationTitle: '跨链通信',
    crossChainCommunicationSubtitle: 'ICWChain支持跨链通信，未来将建立自己的去中心化的跨币交易所。',
    engineTitle: '高效处理引擎',
    engineSubtitle: 'ICWChain还采用超并行交易处理引擎，允许网络同时处理数千个智能合约调用。',

    ICWTechLight1: 'ICWChain 技术',
    ICWTechLight2: '亮点',

    ICWTechLightSub: 'ICWChain作为新一代公有链底层基础设施，采取DPOS+PoW混合共识机制，在技术层面设计了高度模块化和极具扩展性的应用层构造，使跨链通信、平行链搭建、链外信息存储更加便捷，做到了链上撮合、秒级出块。',
    techIntroduction: '技术介绍',
    succeed: '成功',
    whitePaperCn: 'ICWChain 白皮书 中文版',
    whitePaperEn: 'ICWChain 白皮书 英文版',
    decentralizationSubContent1: '去中心化——DPOS+POW混合共识机制',
    decentralizationSubContent2: 'ICWChain社区成员通过投票选举出竞选节点，竞选节点通过算力比拼争夺超级节点席位，并取得该轮次记账权；记账节点完成区块打包后结束本次记账任务。随后通过以上流程选举新一轮超级节点，并进入新一轮记账工作。',
    decentralizationSubContent3: '高性能、低延迟——百万级TPS，秒级验证',
    decentralizationSubContent4: '在已经保证去中心化程度的前提下，进一步提高交易处理效率， 最快可达秒级交易速率。',
    decentralizationSubContent5: '安全—— 双重共识防御， 提高作恶难度',
    decentralizationSubContent6: 'ICWChain采用混合共识机制，对记账组进行多次轮换选举，增加节点作恶难度和作恶成本，从而保障了用户的数字资产安全。',
    contractSubContent: 'ICWChain延申出一种完全去中心化的链上撮合与交易订单管理的解决方案，基于ICWChain独创的通用交易智能合约，将传统聚合撮合的模块中所有交易对拆分成各自的模块（智能合约），以实现链上资产的完全去中心化交易。当持有其他币种的用户在ICWChain的代币上编写并发起合约时，需调用ICWChain通用交易智能合约并绑定一个或多个交易对，且交易对的币种需为ICWChain上发行的合约币进行交易，与此同时，通用交易合约自带撮合交易功能，用户可直接在合约中挂单，完成链上资产的去中心化交易。',
    contractSubContent1: 'ICWChain延申出一种完全去中心化的链上撮合与交易订单管理的解决方案，基于ICWChain独创的通用交易智能合约，将传统聚合撮合的模块中所有交易对拆分成各自的模块（智能合约），以实现链上资产的完全去中心化交易。',
    contractSubContent2: '当持有其他币种的用户在ICWChain的代币上编写并发起合约时，需调用ICWChain通用交易智能合约并绑定一个或多个交易对，且交易对的币种需为ICWChain上发行的合约币进行交易，与此同时，通用交易合约自带撮合交易功能，用户可直接在合约中挂单，完成链上资产的去中心化交易。',
    highlyModularSubContent: 'ICWChain所参考的NULS模块化基础架构，属ICWChain生态最根基的部分。NULS的模块架构具有热插拔、高效、长久、不过时等特性。良好的模块化能够大大降低区块链的整体复杂度，使链的设计、调试和维护变得简单化；各个模块仍可保持独立运行。NULS模块提供了标准定义“Module”，所有ICWChain的模块都遵循NULSModule模块定义。ICWChain模块管理器负责管理所有模块，定义了模块加载/卸载、服务注册等规范，凡是遵循NULSModule规范的扩展模块都可以通过模块管理器加载到ICWChain节点运行。',
    highlyModularSubContent1: 'ICWChain所参考的NULS模块化基础架构，属ICWChain生态最根基的部分。NULS的模块架构具有热插拔、高效、长久、不过时等特性。良好的模块化能够大大降低区块链的整体复杂度，使链的设计、调试和维护变得简单化；各个模块仍可保持独立运行。NULS模块提供了标准定义“Module”，所有ICWChain的模块都遵循NULSModule模块定义。',
    highlyModularSubContent2: 'ICWChain模块管理器负责管理所有模块，定义了模块加载/卸载、服务注册等规范，凡是遵循NULSModule规范的扩展模块都可以通过模块管理器加载到ICWChain节点运行。',
    crossChainCommunicationSubContent: 'ICWChain设计开发了联盟链跨链网关，通过该网关,实现了联盟链和主流的公有链之间的数据和Token的互联互通。通过IBC协议实现了Token的跨链流转。。',
    engineSubContent: 'ICWChain采用了超并行交易处理引擎，允许网络同时处理数千个智能合约调用。通过其流水线基础设施，ICWChain还可以更快地验证交易并跨节点复制交易信息，不论其硬件规格如何。',
    techRoadMap1: 'ICW轻钱包开发，ICW去中心化交易所开发',
    techRoadMap2: '去中心化交易所V1上线',
    techRoadMap3: '去中心化交易所V1升级，轻钱包上线',
    techRoadMap4: '去中心化交易所升级V2版本，研发轻钱包支持多链',
    techRoadMap5: '多链钱包升级完成，并上线第三方钱包',
    techRoadMap6: 'ICW合约模块升级，ICW实现多链交互  ',

    ICWCHAIN: 'ICW链',
    adopts: 'ICWChain采用DPOS+POW混合共识机制，在技术层面设计了高度模块化、可扩展的应用层结构，使得跨链通信、并行链构建和链外信息存储更加便捷。',

    ecologicalNetTit: 'ICWChain生态网络',
    ecologicalNetTit1: 'ICWChain生态',
    ecologicalNetTit2: '网络',

    ecologicalSub: 'ICWChain拥有一个完全“自由平等、民主自治、协作共享”的多元化社交网络生态系统，是全体社区成员建立在“视野共识、权益共识、价值共识、治理共识、收益共识、技术共识”基础上发展的。',
    ecologicalSub1: 'ICWChain拥有一个完全“自由平等、民主自治、协作共享”的多元化社交网络生态系统，',
    ecologicalSub2: '让我们一起构建开放的网络。',
    ecologicalAppTit: 'ICWChain生态应用',

    Find1: '快速找到我们的生态',
    Find2: '链?',
    development: '生态平衡健康发展',
    ICWNode: 'ICW 节点',
    incentive: 'ICW节点激励计划和福利',
    section: '会计科',
    bookkeeping: '记账组由15个共识节点组成，并在12秒内轮询区块。记账周期为30分钟（每个节点10个块，共150个块）。簿记期为每天7200块。区块奖励每四年减半，区块奖励为200ICW/区块。',
    ServiceNode: '服务节点',
    servicenode: '服务节点为同步账本服务，每记账周期（30分钟）奖励一次，每天奖励48次。同步奖励每4年减半。',
    Grossincome: '总收入',
    Grossincomes: '如果节点质押达到500万ICW。区块生产的前提是，如果在三分钟内生产ablock，则回报为200ICW/区块，预期回报为一小时4000ICW，一天96000ICW，以及一年3504000ICW。',

    ecologicalAppTitle1: '数字身份与可信网络',
    ecologicalAppTitle2: '金融领域与 DeFi',
    ecologicalAppTitle3: '去中心化社交',
    ecologicalAppTitle4: '电商与跨境支付',
    ecologicalAppTitle5: '版权保护与 NFT',
    ecologicalAppTitle6: '慈善公益',
    ecologicalContent1: 'ICWChain 区块链分布式存储系统是将大量的普通服务器，通过网络互联，对外作为一个整体提供存储服务,利用块链式数据结构来验证和存储数据，利用分布式节点共识算法生成和更新数据，维护完整、有效且安全的数据库。',
    ecologicalContent2: 'ICWChain界定数字世界的新型资产定义，实现代表任何资产的数据资产的线上权益界定以及交易行为的保障。ICWChain 区块链网络将接入和构建主流可信的金融行业区块链系统，通过 Token安全转移和兑换机制，实现金融行业各类应用的协同。',
    ecologicalContent3: 'ICWChain 通过区块链技术给内容社交平台注入新的血液，Eseeworld作为ICWChain生态应用中的重要一员，利用分布式管理将平台去中心化。',
    ecologicalContent4: 'ICWChain 将区块链技术应用在证券交易中，各参与方便可独立地完成整个结算流程。ICWChain将点对点的技术应用到跨境支付领域后，汇款通道变得透明，交易历史数据可追溯，让收款方、汇款方都安心，也大大提高了效率和速度。',
    ecologicalContent5: 'ICWChain 依托区块链网络本身特性，为元宇宙中的稀有属性、稀有资产提供证明和确权，从而实现数据内容的价值流转。通过映射数字资产，用户在“元宇宙”世界里的装备道具、土地产权等等也会有可交易的实体。',
    ecologicalContent6: '区块链技术可以大大提高慈善领域的信息透明度，让捐赠者有权对慈善体系和流程进行监督，同时也保护了捐赠方和受赠方的隐私。',

    mission: 'ICWChain 使命',
    mission1: 'ICWChain',
    mission2: '使命',

    contactSub: '相对于中心化的互联网平台来说，区块链具有更稳定、抗风险能力更强、更公平、更简单的特点。ICWChain改变了信息交互形态，解决了用中心化思路不可能突破的应用难点，推动着数字经济建设的落地，让web3.0与分布式思想紧密的结合在了一起。',

    vipkefu: 'VIP专属客服(微信)',
    community: 'ICWChain官方社区',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'ICW创世节点全球节点招募',
    userManual: '用户手册',
    selfCreation: '自主创建',
    replaceCreation: '代建节点',
    agentConstruction: '代建节点',
    installtion: '节点安装包',
    account: '请输入您的名字或者企业名称',
    mobileAccount: '请输入您的手机号',
    emailAccount: '请输入您的联系邮箱',
    infoText1: '用户可通过平台购买服务器托管服务，享受1V1客户服务。',
    infoText2: '客户填写购买意向单之后，客户经理将发送邮件指引客户完成后续服务器购买操作',
    thirdPartyServices: '本服务由第三方ESEEWORLD提供',
    apply: '立即申请',
    advTitle1: 'ICWChain公链主网即将上线',
    advTitle2: '现面向全球进行创世节点招募',
    advContent1: 'ICWChain是一条兼顾去中心化、高性能和数据安全的创新型公有链网络。',
    advContent2: 'ICWChain将作为第三代互联网科技革命浪潮中的创新者与领航员，以推动全球数字化经济体系建设为前行动力和终极使命。',
    nrTitle: '节点招募期',
    ndTitle: '节点部署期',
    nrTime: '5月26日  下午12：00-6月15日',
    ndTime: '6月15日  下午12：00-6月20日',
    ndTime1: '节点上线及运行',
    nrContetn1: '•     用户可通过平台购买服务器托管服务，提供1V1客户服务。',
    nrContetn2: '•     每3个月托管服务奖励20,000个ICW（价值20u），多购多得， 早加入早受益，详情可咨询客服。',
    ndContetn1: '• 节点招募期内，通过咨询客户经理购买托管服务器的用户，均可享受ICW空投奖励，该奖励可直接用于创建节点的质押。',
    recruitmentTit: 'ICW创世节点奖励计划与收益',
    accountingTitle: '记账组',
    accountingContent: '记账组由15个共识节点组成，轮询出块，出块时间6秒，15分钟作为一个记账周期（每个节点出10个块，共150个块），每天出14,400块。 出块奖励每4年减半，初始出块奖励100枚/块。 初始每天奖励144万枚，每年5.256亿枚，21.024亿减半。',
    nodeTitle: '服务节点',
    nodeContent: '服务节点为同步账本服务，每记账周期（15分钟）奖励一次，每天奖励96次；同步奖励每4年减半，初始7500枚/记账周期（ 所有同步节点平均分配）.初始每天奖励72万枚，每年2.628亿枚，10.512亿减半。',
    profitTitle: '总收益',
    profitContent: '若按节点质押达到50万ICW，且必然出块的先决条件，1分半出一个块，收益为100ICW/块，1小时预计收益4,000ICW，一天预计收益96,000ICW，一年预计收益35,040,000ICW， 则预计收获70倍左右回报。',
    nodeCreation: '节点创建',
    qualification: '节点创建资格',
    process: '节点创建流程',
    pledge: '节点质押',

    // create global world
    networkEnvironmentSettings: '网络环境配置',
    deposit: '保证金≥20,000枚ICW',

    edaos: 'EDAO发展基金会',
    edao: 'EDAO 基金会致力于支持ICWChain 协议、去中心化网络和开发者生态系统的成长和发展。',
    Social: '官方社交平台',
    Follow: '关注我们，了解更多关于 ICW 的信息',
    Discord: 'Discord是一款语音和文字聊天应用，web3主流社区聊天通讯软件。',
    github: '在线软件源代码托管服务平台。',
    Twitters: '致力于为公众对话服务。',
    ljgd: '加入我们',
    Telegrams: '世界上最受欢迎的跨平台即时通讯软件之一。',
    voice: '用户更专注于游戏本身，流畅的语音占用更少的CPU',
    safe: '既安全又免费',
    Support: '同时支持大量用户在线交流，标准化兴趣社区',

    serverSetup: '服务器搭建',
    serverSetup1: 'A.自主配置网络',
    serverSetup2: 'B.服务器代建',
    loginWallet: '登录ICW钱包',
    fillinInfo: '填写节点信息',
    depositPayment: '缴纳保证金',
    creationSucceeded: '节点创建成功等待质押完成',
    ICWPledge: '进行ICW质押',
    nodeOperation: '质押量超过20万枚ICW，节点即可运行',
    more: '你想知道更多关于ICW Chain的信息',

    // 升级弹窗
    DearICWChainPartner: '尊敬的ICWChain的合作伙伴：',
    OK: '知道了',
    popupDate: 'ICWChain将于2022年9月24日9:00-18:00（UTC/GMT+08:00）进行网络升级，',
    popupWarn: '升级期间建议不要进行有关资产转移的任何操作！',
    popupText1: '本次升级内容主要包括：优化难度共识算法、优化区块打包效率、优化节点投票共识。',
    popupText2: '本次升级完成后，记账组节点出块时间将调整为12s/区块（即记账组轮换调整为1800s/周期）',
    popupText3: '出块奖励调整为200ICW/区块，同步共识奖励调整为15000ICW/记账组周期。',
    popupText4: '同时，本次升级完成后，ICWChain将支持USDT-IRC20的发行。',

    // 头部banner
    hbtitle: '超级以太坊募集(第一期)',
    // hbtime: '2022.11.04 14:00:00 ~ 2022.11.10 09:59:59',
    hbtime: '2022.11.10 10:00:00 ~ 2022.12.2 17:00:00',
    hbBtn: '了解详情',
    // hbuy: '立即购买',
    hbuy: '已售罄',
    // 弹窗
    name: 'SETH CHAIN',
    diallogtext1: '全新DPOS+POW混合共识机制',
    diallogtext2: '优化以太坊共识',
    diallogtext3: '支持以太坊矿机和虚拟机',
    diallogtext4: '促进原以太坊矿工迁移和链上生态建设',
    dialogT: '募集第一期',
    begin: '起：',
    dialogStart: '2022.11.10 10:00:00',
    end: '止：',
    dialogEnd: '2022.11.25 17:00:00',
    dialogInfo: '20U ',
    dialogOline: '主网上线：',
    dialogOlineTime: '2023年3月',
    dialogKnow: '知道了',
    address: '地址',
    city2: '马来西亚',
    city2Ad: '加兰·梅迪卡市纳闽金融公园主办公大楼15层(A1)87000马来西亚纳闽联邦领土',
    city1: '新加坡',
    city1Ad: '73 Upper Paya Lebar Road #06-01C Centro Bianco Singapore 534818',
    gmail: '官方邮箱',
    emailAddress: 'admin@icwchain.com'
}
