export default {
    home: 'HOME',
    technology: 'TECHNOLOGY',
    ecological: 'ECOSYSTEM',
    browser: 'BLOCKCHAIN BROWSER',
    recruitment: 'NODE',
    wallet: 'WALLET',
    contact: 'CONTACT US',
    joinUs: 'JOIN US',
    partner: 'Cooperative partner',
    language: 'LANGUAGE',
    learnMore: 'Learn more',
    company: 'EDAO DEVELOPMENT FOUNDATION LTD.',
    funds: 'ICW Fund',
    lucky: 'Lucky turntable',
    hashGuess: 'Hash contest',
    prize: 'A hundred percent win, huge prize pool',
    prizeBtn: 'GO',

    White: 'White paper download',
    Introduction: 'Introduction ICW Chain',

    Numbertransactions: 'Number transactions',
    Blockheight: 'Block height',
    Coretoken: 'Total Stake',

    aboutICW: 'About ICW',
    whitePaper: 'White Paper',
    ICWwhitePaper: 'ICWChain White paper ',
    technologyFunctions: 'Technology & Functions',
    roadmap: 'road map',
    map: 'Road map',

    welcometoICW: 'WELCOME  TO  ICW CHAIN',
    bannerText: 'ICWChain comes from “I Ching way”, and ”Ching” means ”transformation, change and innovation”. ICWChain is an innovative public chain network that takes into account decentralization, high performance and data security.',
    whitePaperDownload: 'White paper download link',
    whychoose: 'Why choose',
    aspires: 'ICW aspires to be the navigator of WEB3.0',
    decentralizationTitle: 'Decentralization, security and high performance',
    decentralizationTitle1: 'Decentralization, ',
    decentralizationTitle2: 'security and high performance',
    decentralizationSubtitle: 'ICWChain adopts DPOS + POW hybrid consensus model to improve the decentralization of blockchain network and the fairness of community by increasing the participation threshold of nodes and the randomness of accounting group.',
    contractTitle: 'General trading smart contract',
    contractSubtitle: 'ICWChain extends a completely decentralized solution for online matchmaking and transaction order management.',
    highlyModularTitle: 'Highly modular',
    highlyModularSubtitle: 'The NULS module architecture referenced by ICWChain has the characteristics of hot plug, high efficiency, long-term and no time, which greatly reduces the overall complexity of the blockchain and simplifies the design, debugging and maintenance of the blockchain.',
    crossChainCommunicationTitle: 'Cross Chain Communication',
    crossChainCommunicationSubtitle: 'Establish a cross currency communication chain to support the cross currency exchange in the future.',
    engineSubtitle: 'ICWChain also adopts a super parallel transaction processing engine, which allows the network to process thousands of smart contract calls at the same time.',

    ICWTechLight1: 'ICWCHAIN TECHNICAL',
    ICWTechLight2: 'HIGHLIGHTS',

    ICWTechLightSub: 'ICWChain adopts DPOS + POW hybrid consensus mechanism, and designs a highly modular and scalable application layer structure at the technical level, which makes cross chain communication, parallel chain construction and information storage outside the chain more convenient.',
    techIntroduction: 'Technical introduction',
    succeed: 'Succeed',
    whitePaperCn: 'ICWChain White paper Chinese Version',
    whitePaperEn: 'ICWChain White paper English Version',
    decentralizationSubContent1: 'Decentralization - DPOS+POW mixed consensus mechanism',
    decentralizationSubContent2: 'ICWChain community members elect election nodes through voting, and election nodes compete for super node seats through computational power competition and obtain the accounting right of the round; The bookkeeping node ends this bookkeeping task after completing the block packaging. Then select a new round of super nodes through the above process and enter a new round of bookkeeping.',
    decentralizationSubContent3: 'high performance and low latency - million TPS, second level verification',
    decentralizationSubContent4: 'On the premise of ensuring the degree of decentralization, further improve the transaction processing efficiency, and the fastest transaction rate can reach second level.',
    decentralizationSubContent5: 'Security - double consensus defense and improve the difficulty of doing evil',
    decentralizationSubContent6: "ICWChain adopts a mixed consensus mechanism to conduct multiple rotation elections for the accounting group, increasing the difficulty and cost of doing evil at nodes, so as to ensure the security of users' digital assets.",
    contractSubContent: 'ICWChain extended a completely decentralized solution for on chain matching and transaction order management. Based on the general transaction smart contract originally created by ICWChain, all transaction pairs in the traditional aggregation matching module are divided into their own modules (smart contract), so as to realize the completely decentralized transaction of assets on the chain when users with other currencies write and initiate contracts on the tokens of ICWChain, they need to call the ICWChain general transaction smart contract and bind one or more transaction pairs, and the currency of the transaction pair needs to be the contract currency issued on ICWChain for transaction. At the same time, the general transaction contract has its own matching transaction function, and users can directly hang orders in the contract to complete the decentralized transaction of assets on the chain.',
    contractSubContent1: 'ICWChain extended a completely decentralized solution for on chain matching and transaction order management. Based on the general transaction smart contract originally created by ICWChain, all transaction pairs in the traditional aggregation matching module are divided into their own modules (smart contract), so as to realize the completely decentralized transaction of assets on the chain.',
    contractSubContent2: 'when users with other currencies write and initiate contracts on the tokens of ICWChain, they need to call the ICWChain general transaction smart contract and bind one or more transaction pairs, and the currency of the transaction pair needs to be the contract currency issued on ICWChain for transaction. At the same time, the general transaction contract has its own matching transaction function, and users can directly hang orders in the contract to complete the decentralized transaction of assets on the chain.',
    highlyModularSubContent: 'The NULS modular infrastructure referenced by ICWChain is the most fundamental part of ICWChain ecology. The module architecture of NULS has the characteristics of hot plug, high efficiency, long-term, no time and so on. Good modularity can greatly reduce the overall complexity of blockchain and simplify the design, debugging and maintenance of blockchain; Each module can still operate independently NULS module provides a standard definition of “module”. All ICWChain modules follow the NULSmodule definition. ICWChain module manager is responsible for managing all modules and defining specifications such as module loading / unloading and service registration. All extension modules that follow NULSmodule specification can be loaded into ICWChain node through module manager.',
    highlyModularSubContent1: 'The NULS modular infrastructure referenced by ICWChain is the most fundamental part of ICWChain ecology. The module architecture of NULS has the characteristics of hot plug, high efficiency, long-term, no time and so on. Good modularity can greatly reduce the overall complexity of blockchain and simplify the design, debugging and maintenance of blockchain; Each module can still operate independently NULS module provides a standard definition of “module”.',
    highlyModularSubContent2: 'All ICWChain modules follow the NULSmodule definition. ICWChain module manager is responsible for managing all modules and defining specifications such as module loading / unloading and service registration. All extension modules that follow NULSmodule specification can be loaded into ICWChain node through module manager.',
    crossChainCommunicationSubContent: 'ICWChain has designed and developed a cross chain gateway of alliance chain. Through this gateway, the interconnection of data and token between alliance chain and mainstream public chain is realized. The cross chain flow of token is realized through IBC protocol.',
    engineSubContent: 'ICWChain adopts a super parallel transaction processing engine, which allows the network to process thousands of smart contract calls at the same time. Through its pipeline infrastructure, ICWChain can also verify transactions faster and copy transaction information across nodes, regardless of its hardware specifications.',
    techRoadMap1: 'ICW SPV Wallet Development   ICW DEX Development',
    techRoadMap2: 'Launch of Decentralized Exchange V1',
    techRoadMap3: 'Upgrade of Decentralized Exchange V1 and launch of a lightweight wallet',
    techRoadMap4: 'Decentralized Exchange upgrade to V2 with multi-chain lightweight wallet development',
    techRoadMap5: 'Completion of the upgrade for the multi-chain wallet and launch of third-party wallet support',
    techRoadMap6: 'ICW contract module upgrade and implementation of multi-chain interaction for ICW',
    ICWCHAIN: 'ICW CHAIN',
    adopts: 'ICWChain adopts DPOS + POW hybrid consensus mechanism, and designs a highly modular and scalable application layer structure at the technical level, which makes cross chain communication, parallel chain construction and information storage outside the chain more convenient.',

    ecologicalNetTit: 'ICWCHAIN ECOLOGICAL NETWORK',
    ecologicalNetTit1: 'ICWCHAIN ECOLOGICAL',
    ecologicalNetTit2: 'NETWORK',
    ecologicalSub: 'ICWChain has a diversified social network ecosystem with complete “freedom and equality, democracy and autonomy, cooperation and sharing”, which is developed by all community members on the basis of ”vision consensus, rights and interests consensus, value consensus, governance consensus, income consensus and technology consensus“.',
    ecologicalSub1: 'ICWChain has a diversified social network ecosystem with complete “freedom and equality,democracy and autonomy, cooperation and sharing ”，',
    ecologicalSub2: "Let's building the open web together.",
    ecologicalAppTit: 'ICWChain ecological application',

    Find1: 'Find our ecology quickly',
    Find2: 'CHAIN?',
    development: 'Balanced and sound ecological development',
    ICWNode: 'ICW Node',
    incentive: 'ICW node incentive programs and benefits',
    section: 'Accounting section',
    bookkeeping: 'The bookkeeping group consists of 15 consensus nodes and polls out blocks in 12 seconds. Thebookkeeping period is 30 minutes (10 blocks per node, 150 blocks in total). The bookkeepingperiod is 7200 blocks per day. Block reward is halved every four years, and block reward is 200ICW/block.',
    ServiceNode: 'Service node',
    servicenode: 'The service node is synchronous ledger service, which will be rewarded once per bookkeepingcycle (30 minutes) and 48 times per day. The synchronous reward is halved every 4 years.',
    Grossincome: 'Gross income',
    Grossincomes: 'If the node pledge reaches 5 million ICW. The prerequisite of block production is that if ablock is produced in three minutes, the return is 200ICW/ block, the expected return is 4000ICWfor an hour, 96,000ICW for a day, and 35,040,000ICW for a year.',

    ecologicalAppTitle1: 'Digital identity and trusted network',
    ecologicalAppTitle2: 'Financial field and DeFi',
    ecologicalAppTitle3: 'Decentralized social networking',
    ecologicalAppTitle4: 'E-commerce and cross-border payment',
    ecologicalAppTitle5: 'Copyright protection and NFT',
    ecologicalAppTitle6: 'Charity',
    ecologicalContent1: 'ICWChain blockchain distributed storage system connects a large number of ordinary servers, provides storage services as a whole through network interconnection, uses block chain data structure to verify and store data, uses distributed node consensus algorithm to generate and update data, and maintains a complete, effective and secure database.',
    ecologicalContent2: 'ICWChain defines the new asset definition of the digital world, and realizes the definition of online rights and interests of data assets representing any asset and the protection of transaction behavior ICWChain blockchain network will access and build a mainstream and trusted blockchain system in the financial industry, and realize the collaboration of various applications in the financial industry through token secure transfer and exchange mechanism.',
    ecologicalContent3: 'ICWChain injects new blood into the content social platform through blockchain technology.  As an important member ofICWChain ecological application, eseeworld uses distributed management to decentralize the platform.',
    ecologicalContent4: 'ICWChain applies blockchain technology to securities trading, which makes it convenient for each participant to complete the whole settlement process independently  after ICWChain applies point - to - point technology to the field of cross - border payment, the remittance channel becomes transparent and the transaction history data can be traced, which not only reassures the payee and the remitter, but also greatly improves the efficiency and speed.',
    ecologicalContent5: "Relying on the characteristics of blockchain network, ICWChain provides  proof and confirmation of rights for rare attributes and rare assets in the meta universe, so as to realize the value flow of data content by mapping digital assets, users' equipment, props, land property rights, etc. in the “meta universe” world will also have tradable entities.",
    ecologicalContent6: 'Blockchain technology can greatly improve the information transparency in the field of charity, give donors the right to supervise the charity system and processes, and protect the privacy of donors and recipients.',

    mission: 'ICWCHAIN MISSION',
    mission1: 'ICW CHAIN',
    mission2: 'MISSION',
    contactSub: 'Compared with the centralized Internet platform, blockchain has the characteristics of more stability, stronger anti risk ability, fairer and simpler. ICWChain has changed the form of information interaction, solved the application difficulties that cannot be broken through with centralized ideas, promoted the implementation of digital economy construction, and made Web3.0 is closely combined with the distributed idea.',

    vipkefu: 'VIP service（wechat）',
    community: 'ICWChain Community',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'ICW GENESIS NODE RECRUITMENT',
    userManual: 'User Manual',
    selfCreation: 'Self Creation',
    replaceCreation: 'Agent Construction Service',
    agentConstruction: 'Agent Construction ',
    installtion: 'Node lnstallation Package',
    account: 'Please enter your name or business name',
    mobileAccount: 'Please enter your mobile number',
    emailAccount: 'Please enter your contact email',
    infoText1: 'Users can purchase server hosting services through the platform and enjoy 1V1 customer service.',
    infoText2: 'After the customer fills in the purchase intention form, the account manager will send an email to guide the customer to complete the follow up Server purchase operation',
    thirdPartyServices: 'This service is provided by the third party ESEEWORLD',
    apply: 'Apply Immediately',
    advTitle1: 'ICWChain public chain main network will be launched soon',
    advTitle2: 'Now we are recruiting for Genesis nodes all over the world',
    advContent1: 'ICWChain is an innovative public chain network that takes into account decentralization, high performance and data security.',
    advContent2: 'ICWChain will be an innovator and navigator in the wave of the third generation Internet Science and technology revolution, and take promoting the construction of the global digital economic system as the former action force and ultimate mission.',
    nrTitle: 'Node Recruitment Period',
    ndTitle: 'Node Deployment Period',
    nrTime: '12:00 p.m. on May 26 to June 15',
    ndTime: '12:00 p.m. on June 15 to June 20',
    ndTime1: 'Node online and running',
    nrContetn1: '•     Users can purchase server hosting services through the platform to provide 1v1 customer service.',
    nrContetn2: '•     Every three months, 20,000 ICWS (worth 20u) will be awarded for hosting services. Buy more and get more. Join early and benefit early. For details, please consult customer service.',
    ndContetn1: '•     During the recruitment period of the node, users who purchase the managed server by consulting the customer manager can enjoy the ICW airdrop reward, which can be directly used to create the pledge of the node.',
    recruitmentTit: 'ICW Genesis Node Reward Plan and Revenue',
    accountingTitle: 'Bookkeeping Group',
    accountingContent: 'The bookkeeping group is composed of 15 consensus nodes. They create blocks for 6 seconds in turn, and the bookkeeping period is 15 minutes (each node creates 10 blocks, hence there is a total of 150 blocks), and 14,400 blocks are created every day. The reward for block creation will be halved every 4 years, and the initial reward is 100 ICWs/block. The initial daily reward is 1.44 million ICWs and the annual reward is 525.6 million ICWs. Therefore, when the reward reaches 2.1024 billion, it will be halved.',
    nodeTitle: 'Service Node',
    nodeContent: 'To synchronize ledger services, the service node is rewarded once every bookkeeping period (15 minutes) and 96 times a day. The synchronization reward will be halved every four years, and the initial reward is 7,500 ICWs per bookkeeping period (distributed equally among all synchronization nodes). The initial daily reward is 720,000 ICWs and the annual reward is 262.8 million ICWs. Therefore, when the reward reaches 1.0512 billion, it will be halved.',
    profitTitle: 'Total Revenue',
    profitContent: 'If the node pledge reaches 500,000 ICW and that the block is bound to be generated (our prerequisite), a block will be generated in 1 minute and a half, and the income will be 100 ICWs/block. The estimated income is 4,000 ICWs in an hour, 96,000 ICWs in a day, and 35,040,000 ICWs in a year, and the return is expected to be about 70 times.',
    nodeCreation: 'Node Creation',
    qualification: 'Node Creation Qualification',
    process: 'Node Creation Process',
    pledge: 'Node Pledge',

    // create global world
    networkEnvironmentSettings: 'Network environment configuration',
    deposit: 'Deposit ≥ 20,000 ICW',

    edaos: 'EDAO Development Foundation',
    edao: 'The EDAO Foundation is dedicated to supporting the growth and development of the ICWChain protocol, decentralized networks, and developer ecosystems.',
    Social: 'Social Platform',
    Follow: 'Follow us and learn more about ICW',
    Discord: 'Discord is an app for voice and text chat,Mainstream community chat communication software of web3.',
    github: 'Online software source code hosting service platform',
    Twitters: 'Committed to serving public dialogue',
    Telegrams: 'One of the most popular cross-platform instant messaging software in the world',
    ljgd: 'Join us',
    voice: 'The user is more focused on the game itself, and the smooth voice takes less CPU',
    safe: "It's safe and free",
    Support: 'Support a large number of users online communication at the same time, standardized interest community',
    serverSetup: 'Server setup',
    serverSetup1: 'A.Self configuring network',
    serverSetup2: 'B.Server agent',
    loginWallet: 'Log in to ICW Wallet',
    fillinInfo: 'Fill in node information',
    depositPayment: 'Deposit payment',
    creationSucceeded: 'Node creation succeeded, waiting for pledge to complete',
    ICWPledge: 'ICW pledge',
    nodeOperation: 'If the pledge amount exceeds 200,000 ICWS, the node can run',
    more: 'Would you like to know more about ICW Chain',

    // 升级弹窗
    DearICWChainPartner: 'Dear ICWChain Partner',
    OK: 'OK',
    popupDate: 'ICWChain will upgrade its Node at 9:00-18:00 (UTC/GMT+08:00) on September 24, 2022 .',
    popupWarn: 'It is recommended not to perform any operations related to asset transfer during the upgrade!',
    popupText1: 'This upgrade mainly includes: optimization of difficulty consensus algorithm, optimization of block packaging efficiency, and optimization of node voting consensus.',
    popupText2: 'After the upgrade is completed, the block out time of the accounting group node will be adjusted to 12s/block (that is, the rotation of the accounting will be adjusted to 1800s/cycle),',
    popupText3: 'the block out reward will be adjusted to 200ICW/block, and the synchronous consensus reward will be adjusted to 15000ICW/accounting group cycle. Users of each node must upgrade their own nodes between 9:00-18:00 (UTC/GMT+08:00) on 、September 24. Any node loss caused by delayed upgrade should be borne by yourself.',
    popupText4: 'At the same time, after this upgrade, ICWChain will support the release of USDT-IRC20.',

    // 头部banner
    hbtitle: 'SETH ICO (Phase I)',
    hbtime: '2022/11/10 10:00:00 (UTC+8) ~ 2022/12/2 17:00:00 (UTC+8)',
    hbBtn: 'Learn more',
    hbuy: 'Sold out',
    // hbuy: 'buy',
    // 弹窗
    name: 'SETH CHAIN',
    diallogtext1: '',
    diallogtext2: '',
    diallogtext3: '',
    diallogtext4: '',
    dialogT: '',
    begin: '',
    dialogStart: '',
    end: '',
    dialogEnd: '',
    dialogInfo: '20U ',
    dialogOline: '',
    dialogOlineTime: '',
    dialogKnow: 'OK',
    address: 'address',
    city2: 'Malaysia',
    city2Ad: 'Level 15(A1), Main Office Tower, Financial Park Labuan, Jalan Merdeka, 87000 Federal Territory of Labuan, Malaysia',
    city1: 'Singapore',
    city1Ad: '73 Upper Paya Lebar Road #06-01C Centro Bianco Singapore 534818',
    gmail: 'Gmail',
    emailAddress: 'admin@icwchain.com'
}
