<template>
  <div class="header">
    <div class="header-nav--pc">
      <div class="nav w">
        <img src="../assets/images/logo.png" class="logo" @click="jumper('home')" />
        <ul class="header-nav-list fr">
          <li @click="jumper('home')" >
            <span>{{ "home" | t }}</span>
          </li>
          <li @click="jumper('technology')">
            <span>{{ "technology" | t }}</span>
            <ul class="nav-toggle-down">
              <li @click.stop="downloadPDF">{{ "whitePaper" | t }}</li>
              <li @click="jumper('technology')">
                {{ "technologyFunctions" | t }}
              </li>
              <li @click="jumper('technology')">{{ "roadmap" | t }}</li>
            </ul>
          </li>
          <li @click="jumper('ecological')">
            <span>{{ "ecological" | t }}</span>
          </li>
          <!--<li>
            <span @click="jumper('http://wallet.icwchain.com')">
              {{ "wallet" | t }}
            </span>
          </li>-->
          <li @click="jumper('recruitment')">
            <span>{{ "recruitment" | t }}</span>
          </li>
          <!--<li>
            <span @click="jumper('http://browser.icwchain.com')">
              {{ "browser" | t }}
            </span>
          </li>-->
          <!--<li>
            <span>
              DAPP
            </span>
            <ul class="nav-toggle-down">
              <li @click="fundsClick">{{ "funds" | t }}</li>
              <li @click="luckyClick">{{ "lucky" | t }}</li>
              <li @click="hashGuess">{{ "hashGuess" | t }}</li>
            </ul>
          </li>-->
          <li @click="jumper('contact')">
            <span>{{ "contact" | t }}</span>
          </li>
          <!--<li @click="jumper('partner')">
            <span>{{ "partner" | t }}</span>
          </li>-->
          <li>
            <span>{{ "language" | t }}</span>
            <ul class="nav-toggle-down">
              <li @click="changeLanguage('en')">English</li>
              <li @click="changeLanguage('zh-CN')">中文</li>
            </ul>
          </li>
        </ul>
      </div>

    </div>
    <div class="header-nav--mobile">
            <img
                src="../assets/images/logo.png"
                class="logo"
                @click="jumper('home')"
            />
            <div class="ri-top">
                <li class="nav-language">
                    <span @click="lang = !lang">{{ "language" | t }}</span>
                    <div
                        class="langs"
                        :class="{ lang }"
                    >
                        <span @click="changeLanguage('en')">English</span>
                        <span @click="changeLanguage('zh-CN')">中文</span>
                    </div>
                </li>
                <div
                    class="menu"
                    :class="{ act }"
                    @click="act = !act"
                >
                    <div class="line1"></div>
                    <div class="center-line"></div>
                    <div class="line2"></div>
                </div>
            </div>
            <div
                class="nav-list"
                :class="{ act }"
            >
                <li>
                    <span @click="jumper('home')">{{ "home" | t }}</span>
                </li>
                <li>
                    <div>
                        <span @click="jumper('technology')">{{ "technology" | t }}</span>
                        <span
                            class="right"
                            @click="tegAct = !tegAct"
                        >&gt;</span>
                    </div>
                    <div
                        class="submenu-list"
                        v-if="tegAct"
                    >
                <li @click="jumper('technology')">{{ "whitePaper" | t }}</li>
                <li @click="jumper('technology')">
                    {{ "technologyFunctions" | t }}
                </li>
                <li @click="jumper('technology')">{{ "roadmap" | t }}</li>
            </div>
            </li>
            <li @click="jumper('ecological')">
                <span>{{ "ecological" | t }}</span>
            </li>
            <li @click="jumper('recruitment')">
                <span>{{ "recruitment" | t }}</span>
            </li>
            <!-- <li @click="jumper('http://browser.icwchain.com')">
                <span @click="jumper('http://browser.icwchain.com')">
                    {{ "browser" | t }}
                </span>
            </li> -->
            <li @click="jumper('contact')">
                <span>{{ "contact" | t }}</span>
            </li>
        </div>
    </div>
  <!-- 弹窗 -->
  <div class="dialogV" v-show="dialogVisible">
    <div v-if="this.dialoglang=='en'" class="content_en">
      <div class="kown" @click="close">{{"dialogKnow" | t}}</div>
    </div>
    <div v-else class="content_zh">
      <div class="kown" @click="close">{{"dialogKnow" | t}}</div>
    </div>
  </div>
  </div>
</template>

<script>
import cl from '@/utils/changeLanguage'
import downloadjs from 'downloadjs'
import prizeZh from '../assets/images/prizeZh.png'
import prizeEn from '../assets/images/prizeEn.png'
import prizeZhM from '../assets/images/375prizeZh.png'
import prizeEnM from '../assets/images/375prizeEn.png'

export default {
    name: 'page-header',
    mixins: [cl],
    data() {
        return {
            act: false,
            tegAct: false,
            DAPP: false,
            lang: false,
            dialogVisible: false,
            index: '1',
            dialoglang: '',
            prizeZh,
            prizeEn,
            prizeZhM,
            prizeEnM
        }
    },
    mounted() {
        this.dialoglang = localStorage.getItem('language')
    },
    methods: {
    /**
     * 死路由跳转
     */
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                window.open(nameOrURI)
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        downloadPDF() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl =
        'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' +
        lang +
        '.pdf'
            downloadjs(pdfUrl)
        },
        // 打开弹窗
        handleClick() {
            // this.dialogVisible = true //预热活动弹窗
            // 购买活动h5
            this.dialoglang = localStorage.getItem('language')
            // const href = 'http://8.219.121.179:8082/#/purchase?language=' + this.dialoglang // h5测试地址
            // const href = 'https://seth.icwchain.com/#/purchase?language=' + this.dialoglang // h5正式地址
            // console.log(href, 'href')
            // window.open(href, '_blank')
        },
        // 关闭弹窗
        close() {
            this.dialogVisible = false
        },
        // 跳转到ICW基金
        fundsClick() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://icwfund.icwchain.com/#/home?lang=' + lang)
        },
        // 跳转到幸运抽奖
        luckyClick() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://luckdraw.gamfi.cc/#/home?lang=' + lang)
        },
        // 跳转到哈希竞猜
        hashGuess() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://ihash.gamfi.cc/#/home?lang=' + lang)
        }
    }
}
</script>

<style lang="less">
.header {
  .header-nav--pc {
    position: absolute;
    width: 100%;
    height: 50px;
    .nav {
      height: 61px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 109;

      img.logo {
        width: 140px;
        cursor: pointer;
      }

      ul.header-nav-list {
        height: 100%;
        display: flex;
        margin: 0;
        padding-inline-start: 0;

        > li {
          float: left;
          position: relative;

          span {
            font-family: '思源黑体';
            display: block;
            height: 50px;
            margin: 0 30px;
            line-height: 50px;
            font-size: 14px;
            color: #021829;
            cursor:pointer;
          }

          ul.nav-toggle-down {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-inline-start: 0;
            //background-color: #08192b;
            display: none;
            overflow: hidden;

            li {
              white-space: nowrap;
              display: block;
              text-align: center;
              line-height: 36px;
              margin: 0;
              font-size: 13px;
              cursor: pointer;

              &:hover {
                background-color: #5981bd;
              }
            }
          }

          &:hover {
            ul.nav-toggle-down {
              display: block;
            }
          }
        }
      }
      @media screen and (max-width: 1780px) {
            ul.header-nav-list {
                > li {
                    font-size: 16px;
                    ul.nav-toggle-down {
                        li {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1580px) {
            ul.header-nav-list {
                > li {
                    font-size: 12px;

                    ul.nav-toggle-down {
                        li {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
  }

  .el-dialog__close {
    display: none;
  }
  .el-dialog__body,
  .el-dialog__header {
    padding: 0;
  }
  .dialogV {
    width: 100%;
    height: 100vh;
    background-color: rgb(14 13 13 / 89%);
    position: fixed;
    left: 0;
    top: -0px;
    z-index: 100;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }

  .content_zh,
  .content_en {
    color: #fff;
  }
  .dialogtext {
    text-align: left;
  }
  .yellowT {
    color: rgb(250, 199, 104);
    font-size: 20px;
  }
  .yellow {
    color: rgb(250, 199, 104);
  }
  .header-nav--mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 7.49rem;
        height: 0.69rem;
        box-sizing: border-box;
        background-color: #EFF8FF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.55rem;
        z-index: 99999;

        .logo {
            width: 1.08rem;
        }

        .ri-top {
            display: flex;
            align-items: center;

            .nav-language {
                line-height: 0.34rem;
                font-size: 0.25rem;
                color: #000;
                margin-right: 0.13rem;
                font-weight: 600;
                position: relative;

                .langs {
                    position: absolute;
                    top: 100%;
                    line-height: 2.5;
                    display: flex;
                    flex-direction: column;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 0;
                    overflow: hidden;
                    transition: height 200ms ease;
                    background-color: #fff;

                    span {
                        padding: 0 0.21rem;
                    }

                    &.lang {
                        height: 1.2rem;
                    }

                    span:hover{
                        color: #436BF6;
                    }
                }
            }

            .menu {
                width: 0.24rem;
                height: 0.15rem;
                position: relative;

                .line1 {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #5981bd;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0;
                    left: 0;
                }

                .center-line {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #5981bd;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0.069rem;
                    left: 0;
                }

                .line2 {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #5981bd;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0.13rem;
                    left: 0;
                }
            }

            .menu.act {
                .line1 {
                    top: 0.069rem;
                    width: 0.26rem;
                    transform: rotate(45deg);
                }

                .center-line {
                    display: none;
                }

                .line2 {
                    top: 0.069rem;
                    width: 0.26rem;
                    transform: rotate(135deg);
                }
            }
        }

        .nav-list {
            width: 4.56rem;
            padding: 0.13rem 5%;
            background-color: #FFF;
            height: 100vh;
            position: fixed;
            margin: 0;
            top: 0;
            left: -100%;
            z-index: 9999;
            overflow: auto;
            transition: all 0.3s linear;
            // color: #436BF6;
            color: #000;
            border-radius: 0.17rem;

            > li {
                line-height: 2rem;

                div {
                    display: flex;
                }

                span {
                    font-size: 0.3rem;
                    flex: 1;
                }

                span.right {
                    width: 2em;
                    flex: unset;
                    text-align: center;
                }

                .submenu-list {
                    flex-direction: column;
                    font-size: 0.13rem;
                    line-height: 0.31rem;

                    li {
                        padding-left: 0.13rem;
                    }
                }
            }

            &.act {
                left: 0;
            }
        }
    }

    @media only screen and (min-width: 990px) {

        [class*='--mobile'] {
            display: none;
        }
    }

    @media only screen and (max-width: 990px) {
        [class*='--pc'] {
            display: none;
        }
    }
}
</style>
