export default {
    state: {
        language: 'en'
    },
    mutations: {
        SETLANGUAGE: (state, l) => {
            state.language = l
            window.localStorage.setItem('language', l)
        }
    },
    actions: {
        setLanguage: ({ commit }, l) => commit('SETLANGUAGE', l)
    },
    getters: {
        getLanguage: state => state.language
    }
}
