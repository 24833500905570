<template>
    <div class="home">
        <div class="troduce">
            <div class="w">
                <h1 class="welcome">
                    <span><b>Welcome</b> To <br /> ICW <b>CHAIN</b> </span>
                </h1>
                <p>
                    {{ 'bannerText' | t }}
                </p>
                <!-- <div class="download" @click="download">
                    {{ 'whitePaperDownload' | t }}
                </div> -->
            </div>
        </div>

        <div class="num">
            <div class="w">
                <div class="block fl">
                     <!-- <h3 v-cloak class="blocktext">{{ transactions }}</h3> -->
                     <h3 v-cloak class="blocktext">
                    <CountTo  :startVal='startVal' :endVal='endVal' :duration='duration' />
                    </h3>
                    <h5>{{ 'Numbertransactions' | t }}</h5>
                </div>
                <img src="../assets/images/line.png" alt="" class="fl">
                <div class="block fl">
                     <!-- <h3 v-cloak class="blocktext">{{ height }}</h3> -->
                     <h3 v-cloak class="blocktext">
                    <CountTo  :startVal='startVals' :endVal='endVals' :duration='duration' />
                    </h3>
                    <h5>{{ 'Blockheight' | t }}</h5>
                </div>
                <img src="../assets/images/line.png" alt="" class="fl">
                <div class="block fr">
                     <!-- <h3 v-cloak class="blocktext">{{ TotalStake }}K</h3> -->
                     <h3 v-cloak class="blocktext">
                    <CountTo  :startVal='startValss' :endVal='endValss' :duration='duration' />
                    K</h3>
                    <h5>{{ 'Coretoken' | t }}</h5>
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="w">
                <div class="why">
                    <h2>{{ 'whychoose' | t }} <b>ICW CHAIN</b></h2>
                    <img src="../assets/images/Vector 2.png" alt="" class="hor">
                    <h5>{{ 'aspires' | t }}</h5>
                </div>
                <div class="adv-child fl">
                    <div class="line">
                        <img src="../assets/images/Decen.png" alt="">
                    </div>
                    <div class="title">
                        {{ 'decentralizationTitle1' | t }}<br>
                        {{ 'decentralizationTitle2' | t }}
                    </div>
                    <div class="content">
                        {{ 'decentralizationSubtitle' | t }}
                    </div>
                </div>
                <div class="adv-child fl">
                    <div class="line">
                        <img src="../assets/images/General.png" alt="">
                    </div>
                    <div class="title">
                        {{ 'contractTitle' | t }}
                    </div>
                    <div class="content">
                        {{ 'contractSubtitle' | t }}
                    </div>
                </div>
                <div class="adv-child fr">
                    <div class="line">
                        <img src="../assets/images/Highly.png" alt="">
                    </div>
                    <div class="title">
                        {{ 'highlyModularTitle' | t }}
                    </div>
                    <div class="content">
                        {{ 'highlyModularSubtitle' | t }}
                    </div>
                </div>
            </div>
        </div>
        <div class="socialize">
            <div class="w">
                <div class="terrace">
                    <h2><b>ICW CHAIN</b> {{ 'Social' | t }}</h2>
                    <img src="../assets/images/Vector 2.png" alt="" class="hor">
                    <h5>{{ 'Follow' | t }}</h5>
                </div>
            </div>
            <div class="w">
                <div class="terraceList" @mouseover="over(index)" @mouseout="out(index)"  v-on:click='change(index)' :key='item.id' v-for='(item, index) in terraceList'>
                    <img  :src="item.path1" alt="">
                </div>
            </div>
            <div class="w" >
                <div class="Discord" v-if="this.tabChange !== 1 && this.tabChange !== 2 && this.tabChange !== 3">
                    <h3>Discord</h3>
                    <p>{{ 'Discord' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 1">
                <div class="Discord">
                    <h3>GitHub</h3>
                    <p>{{ 'github' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 2">
                <div class="Discord">
                    <h3>Telegram</h3>
                    <p>{{ 'Telegrams' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 3">
                <div class="Discord">
                    <h3>Twitter</h3>
                    <p>{{ 'Twitters' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                </div>
            </div>
        </div>
        <div class="socialize1">
            <div class="w">
                <div class="terrace">
                    <h2><b>ICW CHAIN</b> {{ 'Social' | t }}</h2>
                    <img src="../assets/images/Vector 2.png" alt="" class="hor">
                    <h5>{{ 'Follow' | t }}</h5>
                </div>
            </div>
            <div class="w">
                <div class="terraceList" @touchstart="gtouchstart(index)" @touchmove="gtouchmove(index)" @touchend="gtouchend(index)" :key='item.id' v-for='(item, index) in terraceList'>
                    <img  :src="item.path1" alt="">
                </div>
            </div>
            <div class="w" >
                <div class="Discord" v-if="this.tabChange !== 1 && this.tabChange !== 2 && this.tabChange !== 3">
                    <h3>Discord</h3>
                    <p>{{ 'Discord' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                    <div class="edit" @click="learnMorefirst">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 1">
                <div class="Discord">
                    <h3>GitHub</h3>
                    <p>{{ 'github' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                    <div class="edit" @click="learnMoretwo">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 2">
                <div class="Discord">
                    <h3>Telegram</h3>
                    <p>{{ 'Telegrams' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                    <div class="edit" @click="learnMorethree">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 3">
                <div class="Discord">
                    <h3>Twitter</h3>
                    <p>{{ 'Twitters' | t }}</p>
                    <!-- <p><img src="../assets/images/voice.png" alt=""> {{ 'voice' | t }}</p>
                    <p><img src="../assets/images/safe.png" alt=""> {{ 'safe' | t }}</p>
                    <p><img src="../assets/images/Support.png" alt=""> {{ 'Support' | t }}</p> -->
                    <div class="edit" @click="learnMorefour">{{ 'ljgd' | t }}</div>
                </div>
            </div>
        </div>
        <div class="Friend" @click="jumper('contact')">
            <div class="w">
                <h2>Hello！New Friend!</h2>
                <p>{{ 'more' | t }}</p>
            </div>
        </div>
        <div class="contact">
            <div class="w">
                <div class="Gmail">
                    <h5><img src="../assets/images/email.png" alt=""> {{ 'gmail' | t }}</h5>
                    <p>{{ 'emailAddress' | t }}</p>
                </div>
                <div class="Address">
                    <h5><img src="../assets/images/address1.png" alt=""> {{ 'address' | t }}</h5>
                    <p><img src="../assets/images/city.png" alt=""> {{ 'city1' | t }}</p>
                    <p>{{ 'city1Ad' | t }}</p>
                    <p><img src="../assets/images/city.png" alt=""> {{ 'city2' | t }}</p>
                    <p>{{ 'city2Ad' | t }}</p>
                </div>
            </div>
        </div>
        <div class="Foundation">
            <div class="w">
                <p>{{ 'edao' | t }}</p>
                <div class="edao">{{ 'edaos' | t }} </div>
            </div>
        </div>
        <!--<div class="advantage">
            <div class="title">
                {{ 'decentralizationTitle' | t }}
            </div>
            <div class="line"></div>
            <div class="adv-info">
                {{ 'decentralizationSubtitle' | t }}
            </div>
            <div class="adv-children">
                <div class="adv-child lf">
                    <div class="title">
                        {{ 'contractTitle' | t }}
                    </div>
                    <div class="line"></div>
                    <div class="content">
                        {{ 'contractSubtitle' | t }}
                    </div>
                </div>
                <div class="adv-child rt">
                    <div class="title">
                        {{ 'highlyModularTitle' | t }}
                    </div>
                    <div class="line"></div>
                    <div class="content">
                        {{ 'highlyModularSubtitle' | t }}
                    </div>
                </div>
            </div>
        </div>-->
        <!-- 升级弹窗 -->
        <!-- <div class="popupWrap" v-if="popupState">
            <div class="close" @click="popupClick">×</div>
            <div class="popup">
                <div class="popupContent">
                    <div class="popupTitle">{{ 'DearICWChainPartner' | t }}</div>
                    <div class="popupDate">{{ 'popupDate' | t }}</div>
                    <div class="popupWarn">{{ 'popupWarn' | t }}</div>
                    <div class="popupText">
                        {{ 'popupText1' | t }}
                        <br>
                        {{ 'popupText2' | t }}
                        <br>
                        {{ 'popupText3' | t }}
                        <br>
                        {{ 'popupText4' | t }}
                    </div>
                </div>
                <div class="popupButton" @click="popupClick">{{ 'OK' | t }}</div>
            </div>
        </div> -->
    </div>
</template>
<script>
import downloadjs from 'downloadjs'
// import Discord from '@/assets/images/Discord.png'
import Discordss from '@/assets/images/Discordss.png'
import Discord1 from '@/assets/images/Discord1.png'
import GitHub from '@/assets/images/GitHub.png'
import GitHub1 from '@/assets/images/GitHub1.png'
import telegram from '@/assets/images/telegram.png'
import telegram1 from '@/assets/images/telegram1.png'
import twitter from '@/assets/images/twitter.png'
import twitter1 from '@/assets/images/twitter1.png'
import CountTo from 'vue-count-to'

export default {
    name: 'home-page',
    data() {
        return {
            startVal: 0,
            startVals: 0,
            startValss: 0,
            endVal: 0,
            endVals: 0,
            endValss: 0,
            duration: 2000,
            popupState: true,
            transactions: '',
            height: '',
            TotalStake: '',
            timer: null,
            startTime: 0, // 初始开始请求时间
            currentIndex: 0,
            userShow: true,
            terraceList: [{
                id: 1,
                path1: Discordss,
                path2: Discord1,
                transfer: ''
            }, {
                id: 2,
                path1: GitHub,
                path2: GitHub1,
                transfer: ''
            }, {
                id: 3,
                path1: telegram,
                path2: telegram1,
                transfer: ''
            }, {
                id: 4,
                path1: twitter,
                path2: twitter1,
                transfer: ''
            }],
            tabChange: '',
            randomNumber: 1,
            randomNumbers: 1,
            randomNumberss: 1
        }
    },
    components: {
        CountTo
    },
    methods: {
        download() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(pdfUrl)
        },
        popupClick() {
            this.popupState = false
        },
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        async getTransactions() {
            this.randomNumber = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/', {
                jsonrpc: '2.0',
                method: 'getCoinInfo',
                params: [
                    1
                ],
                id: 250
            })
            let txCount = res.result.txCount
            txCount = this.addDou(txCount.toString())
            this.transactions = txCount
            this.endVal = res.result.txCount
            this.startVal = this.endVal - this.randomNumber
        },

        async getBlockheight() {
            this.randomNumbers = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getBestBlockHeader',
                    params: [
                        1
                    ],
                    id: 165
                }
            )
            let height = res.result.height
            height = this.addDou(height.toString())
            this.height = height
            this.endVals = res.result.height
            this.startVals = this.endVals - this.randomNumbers
        },

        async getTotalStake() {
            this.randomNumberss = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getCoinInfo',
                    params: [
                        1
                    ],
                    id: 508
                }
            )
            let TotalStake = res.result.consensusTotal
            TotalStake = this.thousands(TotalStake.toString())
            this.TotalStake = TotalStake
            this.endValss = parseFloat(res.result.consensusTotal.toString().slice(0, 9))
            this.startValss = this.endValss - this.randomNumberss
        },
        addDou(num) {
            const numArr = num.split('.')
            num = numArr[0]
            let result = ''
            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }
            if (num) {
                result = num + result
            }
            return result
        },

        thousands(num) {
            num = num / 100000000000
            const str = num.toFixed(2).toString()
            const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
            return str.replace(reg, '$1,')
        },

        time() {
            setInterval(() => {
                setTimeout(() => {
                    this.getBlockheight()
                    this.getTotalStake()
                    this.getTransactions()
                }, 0)
            }, 5000)
        },
        // pc点击跳转
        change: function (index) {
            if (index === 0) {
                window.open('http://discord.gg/M9ZyjwPtu9')
            } else if (index === 1) {
                window.open('https://github.com/icwchain')
            } else if (index === 2) {
                window.open('https://t.me/ICWChaincomm')
            } else if (index === 3) {
                window.open('https://twitter.com/IcwChain')
            }
        },
        // 鼠标移入切换图片
        over(e) {
            this.terraceList[e].transfer = this.terraceList[e].path1
            this.terraceList[e].path1 = this.terraceList[e].path2
            this.tabChange = e
        },
        // 鼠标移出切换图片
        out(e) {
            this.terraceList[e].path1 = this.terraceList[e].transfer
            this.tabChange = e
        },
        // 移动端手指触碰
        gtouchstart(index) {
            console.log('index', index)
            this.terraceList[index].transfer = this.terraceList[index].path1
            this.terraceList[index].path1 = this.terraceList[index].path2
            this.tabChange = index
        },
        // 移动端手指滑动
        gtouchmove(index) {
            console.log('index1', index)
        },
        // 移动端手指离开
        gtouchend(index) {
            setTimeout(() => {
                this.terraceList[index].path1 = this.terraceList[index].transfer
            }, 1000)
            this.tabChange = index
        },
        // 移动端点击跳转页面
        learnMorefirst() {
            window.open('http://discord.gg/M9ZyjwPtu9')
        },
        learnMoretwo() {
            window.open('https://github.com/icwchain')
        },
        learnMorethree() {
            window.open('https://t.me/ICWChaincomm')
        },
        learnMorefour() {
            window.open('https://twitter.com/IcwChain')
        }
    },
    created() {
        this.getTransactions()
        this.getBlockheight()
        this.getTotalStake()
        this.time()
    }

}
</script>
<style lang="less" scoped>
.home {
    .troduce {
        width: 100%;
        height: 718px;
        background: url("../assets/images/bg1.png") no-repeat center top;
        margin: 0 auto;

        .w {
            position: relative;
        }

        iframe.ap.max {
            border: none;
        }

        .welcome {
            font-family: 'Misans';
            width: 493px;
            height: 176px;
            box-sizing: border-box;
            color: #424242;
            font-size: 64px;
            position: absolute;
            top: 175px;
            left: 22px;
            font-weight: bold;

            b {
                color: #246EFF;
            }

        }

        p {
            font-family: 'Poppins';
            width: 590px;
            height: 112px;
            line-height: 30px;
            color: #777E90;
            font-size: 16px;
            position: absolute;
            top: 386px;
            left: 22px;
        }

        .download {
            font-family: 'Poppins';
            width: 270px;
            height: 48px;
            line-height: 48px;
            position: absolute;
            top: 590px;
            background-color: #246EFF;
            left: 22px;
            font-size: 16px;
            color: #FCFCFD;
            text-align: center;
            box-sizing: border-box;
            border-radius: 24px;
            cursor: pointer;

            &:hover {
                background-color: #0043b3;
            }
        }
    }

    .num {
        width: 100%;
        height: 200px;
        color: #FFFFFF;
        background-color: #0054FB;

        img {
            margin-top: 15px;
        }

        .block {
            width: 395px;
            margin-top: 10px;
            text-align: center;

            h3 {
                background-image: -webkit-linear-gradient(#FFFFFF 47%, #94AEFD 89%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-top: 30px;
                font-size: 34px;
                font-weight: 700;
                font-family: 'Poppins';
            }

            h5 {
                font-family: 'Poppins';
                width: 195px;
                margin: 0 auto;
                margin-top: 50px;
                font-size: 18px;
                font-weight: normal;
                color: #DFE7FF;
            }
        }

    }

    .advantage {
        width: 100%;
        height: 860px;
        background: url('../assets/images/bg-1.png') no-repeat center;

        .why {
            font-family: '思源黑体';
            color: #424242;
            width: 600px;
            text-align: center;
            margin: 85px auto 0;

            h2 {
                font-size: 44px;
            }

            b {
                font-family: 'Poppins';
                color: #246EFF;
            }

            .hor {
                width: 39px;
                height: 1px;
                margin: 25px auto;
                text-align: center;
            }

            h5 {
                font-weight: normal;
                color: #94A5C5;
                font-size: 16px;
            }
        }

        .adv-child {
            font-family: '思源黑体';
            box-sizing: border-box;
            padding: 46px 0 0 46px;
            margin-top: 80px;
            width: 340px;
            height: 450px;
            border: 1px solid #EBF1FF;

            .title {
                width: 221px;
                font-size: 22px;
                color: #424242;
                font-weight: 700;
                margin: 25px 0;
            }

            .content {
                width: 228px;
                font-size: 17px;
                color: #7F8CA5;
            }
        }

        .adv-child:nth-child(3) {
            margin: 80px 90px 0;
        }

        .adv-info {
            width: 60%;
            font-size: 26px;
            font-weight: bold;
            color: #ffffff;
            margin-top: 44px;
        }

        .adv-children {
            margin-top: 240px;
            display: flex;

            .adv-child {
                width: 50%;
                height: calc(54vw);
                margin: 20px;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding: 10% 4%;

                .title {
                    width: unset;
                    font-size: 72px;
                    font-family: Poppins;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 68px;
                }

                .content {
                    font-size: 26px;
                    font-family: Poppins;
                    font-weight: bold;
                    color: #ffffff;
                    margin-top: 43px;
                }
            }

            .lf {
                margin-left: 0;
                background-image: url('../assets/images/se-bg-1.png');
            }

            .rt {
                margin-right: 0;
                background-image: url('../assets/images/se-bg-2.png');
            }
        }
    }
    .socialize1{
        display: none;
    }
    .socialize {
        font-family: '思源黑体';
        overflow: hidden;
        width: 100%;
        // height: 920px;
        height: 720px;
        background-color: #F7F9FF;

        .terrace {
            color: #424242;
            text-align: center;
            margin: 125px auto 0;

            h2 {
                font-size: 50px;

                b {
                    color: #246EFF;
                }
            }

            .hor {
                width: 39px;
                height: 1px;
                margin: 25px auto;
                text-align: center;
            }

            h5 {
                font-weight: normal;
                color: #94A5C5;
                font-size: 20px;
            }
        }

        .terraceList {
            float: left;
            width: 230px;
            height: 150px;
            background-color: #FEFEFF;
            text-align: center;
            line-height: 150px;
            margin: 60px 45px;
        }

        .terraceList:first-child {
            margin-left: 0;
        }

        .terraceList:last-child {
            margin-right: 0;
        }

        .Discord {
            padding-left: 0px;

            h3 {
                font-family: 'Poppins';
                font-size: 34px;
                color: #3D3D3D;
            }

            p {
                font-size: 20px;
                color: #7F8CA5;
                line-height: 44px;
            }
        }

    }

    .Friend {
        font-family: 'Poppins';
        width: 100%;
        height: 280px;
        background: url('../assets/images/Friend.png') no-repeat center;
        cursor: pointer;

        h2 {
            padding-top: 60px;
            padding-left: 232px;
            font-size: 60px;
            color: #FFFFFF;
        }

        p {
            padding-top: 15px;
            padding-left: 232px;
            font-size: 18px;
            color: #FFFFFF;
        }
    }

    .contact {
        font-family: '思源黑体';
        box-sizing: border-box;
        width: 100%;
        height: 650px;

        .w {
            background: url('../assets/images/contact.png') no-repeat center;
            height: 540px;
        }

        .Gmail {
            overflow: hidden;
            padding-top: 100px;
            padding-left: 50px;

            h5 {
                font-size: 20px;
                color: #1A315B;
            }

            p {
                margin-top: 25px;
                font-size: 16px;
                color: #1A315B;
            }
        }

        .Address {
            overflow: hidden;
            padding-top: 50px;
            padding-left: 50px;

            h5 {
                font-size: 20px;
                color: #1A315B;
            }

            p {
                width: 597px;
                margin-top: 25px;
                font-size: 16px;
                color: #1A315B;
            }

        }
    }

    .Foundation {
        font-family: 'Poppins';
        width: 100%;
        height: 321px;
        background: url('../assets/images/Foundation.png') no-repeat center;
        text-align: center;

        p {
            padding-top: 80px;
            width: 1074px;
            font-size: 24px;
            color: #FFFFFF;
            margin: 0 auto;
        }

        .edao {
            width: 475px;
            height: 65px;
            line-height: 65px;
            font-size: 24px;
            background-color: #0D59EE;
            border-radius: 50px;
            margin: 50px auto 0;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    /*.popupWrap {
        width: 650px;
        height: 320px;
        background-color: #303841;
        border-radius: 20px 20px 0px 20px;
        padding: 30px;
        position: fixed;
        bottom: 28px;
        right: 36px;
        z-index: 100;

        .close {
            font-size: 34px;
            color: #fff;
            position: absolute;
            top: 10px;
            right: 26px;
            cursor: pointer;
        }

        .popup {
            width: 100%;
            height: 100%;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
            }

            .popupContent {
                width: 100%;
                min-height: 250px;
                font-size: 14px;
                font-family: Source Han Sans SC;
                color: #FFFFFF;

                .popupTitle {
                    font-weight: bold;
                    margin-bottom: 6px;
                }

                .popupWarn {
                    color: #FF0000;
                    font-weight: bold;
                    margin: 6px 0;
                }

                .popupText {
                    line-height: 30px;
                }
            }

            .popupButton {
                width: 160px;
                height: 40px;
                background: #3A5BE9;
                font-family: PingFang SC;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                line-height: 40px;
                font-weight: bold;
                border-radius: 22px;
                margin: 30px auto 0;
                cursor: pointer;
            }
        }
    }*/
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .home {
        background-color: #f3f3f3;

        .w {
            width: 6.1rem;
            margin: 0 auto;
        }

        .troduce {
            background: url(../assets/images/m-bg.png) no-repeat center top;
            background-size: 7.5rem  7.99rem;
            height: 7.98rem;
            .welcome {
                width: 3.07rem;
                font-size: 0.45rem;
                top: 0.79rem;
                left: -0.1rem;
            }

            p {
                font-size: 0.24rem;
                padding: 0;
                top: 2.2rem;
                width: 3.07rem;
                left: -0.1rem;
                line-height: 0.34rem;
                color: #777E90;
            }

            .download {
                left: -0.1rem;
                width: 3.25rem;
                top: 6.2rem;
                height: 0.46rem;
                text-align: center;
                font-size: 0.13rem;
                font-weight: bold;
                color: #246EFF;
                line-height: 0.46rem;
                padding: 0 0;
                background: #fff;
                border: 1px solid #246EFF;
                border-radius: 0.41rem;
            }
        }

        .num {
            width: 6.94rem;
            background-color: #fff;
            height: 2.16rem;
            margin: 0 0.3rem;
            border-radius: 0.33rem;

            .w {
                margin-top: -0.73rem;
            }

            img {
                display: none;
            }

            .block {
                box-sizing: border-box;
                width: 2rem;
                margin: 0;
                padding: 0;

                h3 {
                    margin-top: 0.50rem;
                    color: #000;
                    -webkit-text-fill-color: #000;
                    font-size: 0.24rem;
                }

                h5 {
                    margin-top: 0.3rem;
                    color: #000;
                    font-size: 0.13rem;
                    width: 1.5rem;
                }
            }
        }

        .advantage {
            margin-top: 0.5rem;
            box-sizing: border-box;
            height: 14.28rem;
            background-color: #fff;

            .w {
                width: 6.94rem;
            }

            .why {
                padding-top: 0.75rem;
                margin: 0;
                width: 100%;

                h2 {
                    font-size: 0.26rem;
                    line-height: 0.26rem;
                }

                .hor {
                    margin-top: -1rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                }
            }

            .adv-child:nth-child(3) {
                margin: 0.5rem 0;
                height: 2.62rem;
            }

            .adv-child:nth-child(2) {
                margin-top: 0.5rem;
            }

            .adv-child {
                box-sizing: border-box;
                position: relative;
                margin: 0 auto;
                width: 6.94rem;
                height: 3.9rem;
                padding: 0;
                border-radius: 0.27rem;

                .line {
                    width: 0.69rem;
                    height: 0.69rem;
                    position: absolute;
                    width: 0.69rem;
                    margin-top: 0.36rem;
                    top: -0.5rem;
                    left: 0.41rem;

                    img{
                        width: 0.69rem;
                        height: 0.69rem;
                    }
                }

                .title {
                    position: absolute;
                    width: 2.70rem;
                    font-size: 0.26rem;
                    line-height: 0.31rem;
                    top: 0.1rem;
                    left: 1.4rem;
                    margin: 0.19rem 0;
                }

                .content {
                    position: absolute;
                    width: 4.07rem;
                    font-size: 0.2rem;
                    margin-top: 0.2rem;
                    left: 1.4rem;
                    top: 1.2rem;
                }
            }

            .popupWrap {
                width: 4rem;
                height: 2rem;
                border-radius: 0.1rem 0.1rem 0px 0.1rem;
                padding: 0.15rem;
                position: fixed;
                bottom: 0.14rem;
                right: 0.18rem;
                z-index: 100;

                .close {
                    font-size: 0.3rem;
                    color: #fff;
                    position: absolute;
                    top: 10px;
                    right: 26px;
                    cursor: pointer;
                }

                .popup {
                    width: 100%;
                    height: 100%;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background: rgba(0, 0, 0, 0.2);
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background: rgba(0, 0, 0, 0.1);
                    }

                    .popupContent {
                        width: 100%;
                        min-height: 250px;
                        font-size: 0.14rem;
                        font-family: Source Han Sans SC;
                        color: #FFFFFF;

                        .popupTitle {
                            font-weight: bold;
                            margin-bottom: 6px;
                        }

                        .popupWarn {
                            color: #FF0000;
                            font-weight: bold;
                            margin: 6px 0;
                        }

                        .popupText {
                            line-height: 0.3rem;
                        }
                    }

                    .popupButton {
                        width: 1rem;
                        height: 0.3rem;
                        background: #3A5BE9;
                        font-family: PingFang SC;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 0.3rem;
                        font-weight: bold;
                        border-radius: 22px;
                        margin: 30px auto 0;
                        cursor: pointer;
                    }
                }
            }
        }
        .socialize{
            display: none;
        }
        .socialize1 {
            width: 100%;
            height: 7rem;
            display: block;
            .terrace {
                padding-top: 0.5rem;
                margin: 0 auto;

                h2 {
                    margin-top: 0.35rem;
                    font-size: 0.26rem;
                }

                .hor {
                    margin-top: -1rem;
                    width: 0.31rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                }
            }

            .terraceList {
                float: left;
                width: 1.15rem;
                height: 1.15rem;
                margin: 0.5rem 0.23rem 0;
                line-height: 1.05rem;

                img{
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .terraceList:first-child {
            margin-left: 0;
        }
            .terraceList:last-child {
                margin-right: 0;
            }

            .Discord {
                margin-top: 2.5rem;

                h3 {
                    font-size: 0.24rem;
                }

                p {
                    font-size: 0.22rem;
                    width: 6.68rem;
                    line-height: 0.38rem;
                    margin-top: 0.21rem;
                }
                .edit{
                    width: 1.3rem;
                    margin-top: 0.1rem;
                    font-size: 0.18rem;
                    // background: red;
                    text-align: center;
                    padding: 0.1rem 0;
                    border-radius: 0.5rem;
                    border: 0.01rem solid #246EFF;
                    color: #246EFF;
                }
            }

        }

        .Friend {
            background: url(../assets/images/m-Friend.png) no-repeat center;
            box-sizing: border-box;
            width: 100%;
            height: 1.53rem;
            text-align: center;
            padding-top: 0.28rem;

            h2 {
                font-size: 0.31rem;
                padding: 0;
            }

            p {
                margin-top: 0.28rem;
                font-size: 0.21rem;
                padding: 0;
            }
        }

        .contact {
            margin-top: 0.3rem;
            background-color: #fff;
            width: 100%;
            height: 4.66rem;
            padding: 0.3rem 0;
            font-size: 0.21rem;

            .w {
                background: url(../assets/images/m-contact.png) no-repeat center top;
                background-size: 6.95rem 4.03rem;
                width: 6.95rem;
                height: 4.03rem;

                img{
                    width: 0.23rem;
                    height: 0.23rem;
                }

                .Gmail{
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;

                    h5{
                        font-size: 0.21rem;
                    }

                    p{
                        margin-top: 0.38rem;
                        font-size: 0.21rem;
                    }
                }

                .Address {
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;
                    h5{
                        font-size: 0.21rem;
                    }
                    p{
                        width: 100%;
                        margin: 0.1rem 0 0;
                        font-size: 0.21rem;
                    }
                }
            }
        }
        .Foundation{
            background: url(../assets/images/m-Foundation.png) no-repeat center top;
            height: 2.37rem;

            p{
                width: 6.32rem;
                font-size: 0.17rem;
                padding-top: 0.45rem;
            }
            .edao{
                width: 3.73rem;
                height: 0.47rem;
                font-size: 0.17rem;
                line-height: 0.47rem;
                margin: 0.3rem auto 0;
            }
        }
    }
}
</style>
