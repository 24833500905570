/**
 * @Author: Lucius
 * @Date: 2022-5-25 08:31:17
 * @Last Modified by: Lucius
 * @Last Modified time: 2022-05-25 08:31:28
 */

import { hasOwn } from '@/utils/util'

const RE_NARGS = /(%|)\{([0-9a-zA-Z_]+)}/g

/**
 * String format template
 * - https://github.com/Matt-Esch/string-template/index.js
 */
export default function (Vue) {
    function template(string, ...args) {
        if (args.length === 1 && typeof args[0] === 'object') {
            args = args[0]
        }

        if (!args || !args.hasOwnProperty) {
            args = []
        }

        return string.replace(RE_NARGS, (match, prefix, i, index) => {
            let result

            if (string[index - 1] === '{' &&
                string[index + match.length] === '}') {
                return i
            } else {
                result = hasOwn(args, i) ? args[i] : null
                if (result === null || result === undefined) {
                    return ''
                }

                return result
            }
        })
    }
    return template
}
