import Home from '../views/Home.vue'
import Technology from '../views/technology.vue'
import Ecological from '../views/ecological.vue'
import Recruitment from '../views/recruitment.vue'
import Contact from '../views/contact.vue'
import Partner from '../views/partner.vue'

// const About = () => import(/* webpackChunk: 'about' */ '../views/AboutView.vue')

export default {
    // About,
    Home,
    Technology,
    Ecological,
    Recruitment,
    Contact,
    Partner
}
