<!-- 联系我们 -->
<template>
  <div class="contact">
    <div class="advantage">
      <div class="w">
        <div class="title">
        {{ "mission1" | t }} <br> <b> {{ "mission2" | t }}</b>
      </div>
      <div class="adv-info">
        {{ "contactSub" | t }}
      </div>
      </div>
    </div>
   <div class="ec-main" >
      <div class="w ec-contact">
       <div class="hello">
            <h5>Hello！New Friend!</h5>
            <p> {{ "more" | t }}</p>
        </div>
        <div class="Gmail">
                    <h5><img src="../assets/images/email1.png" alt=""> {{ "gmail" | t }}</h5>
                    <p>admin@icwchain.com</p>
        </div>
        <div class="Address">
            <h5><img src="../assets/images/address1.png" alt=""> {{ 'address' | t }}</h5>
            <p><img src="../assets/images/city.png" alt=""> {{ 'city1' | t }}</p>
            <p>{{ 'city1Ad' | t }}</p>
            <p><img src="../assets/images/city.png" alt=""> {{ 'city2' | t }}</p>
            <p>{{ 'city2Ad' | t }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

//  import twitter from '@/assets/images/logoIcon/twitter.png'
//  import discord from '@/assets/images/logoIcon/discord.png'
//  import telegram from '@/assets/images/logoIcon/telegram.png'
//  import facebook from '@/assets/images/logoIcon/facebook.png'
//  import github from '@/assets/images/logoIcon/github.png'
//  import medium from '@/assets/images/logoIcon/medium.png'
//  import youtube from '@/assets/images/logoIcon/youtube.png'

export default {
    name: 'contact-page',
    data() {
        return {
            /* listData: [
                {
                    alt: 'twitter',
                    img: twitter,
                    link: 'https://twitter.com/IcwChain'
                },
                {
                    alt: 'discord',
                    img: discord,
                    link: 'https://discord.com/channels/983604567205900318/983617925560811581'
                },
                {
                    alt: 'telegram',
                    img: telegram,
                    link: 'https://t.me/ICWChaincomm'
                },
                {
                    alt: 'facebook',
                    img: facebook,
                    link: undefined
                },
                {
                    alt: 'github',
                    img: github,
                    link: 'https://github.com/icwchain'
                },
                {
                    alt: 'medium',
                    img: medium,
                    link: 'https://medium.com/@icwchain_'
                },
                {
                    alt: 'youtube',
                    img: youtube,
                    link: 'https://www.youtube.com/channel/UCN5fLCiMg1b9KVYWMbSWicA'
                }
            ], */
            lang: ''
        }
    },
    mounted() {
        this.lang = localStorage.getItem('language')
        console.log(this.lang)
    },
    methods: {
        linkUrl(url) {
            if (url) {
                window.open(url)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.contact {

  .advantage {
    font-family: 'Poppins';
    width: 100%;
    height: 717px;
    background: url('../assets/images/bg5.png') no-repeat center;
    margin: 0 auto;

    .w{
      position: relative;
    }

    .title {
      font-family: 'MiSans';
    position: absolute;
    top: 135px;
    left: 84px;
    font-weight: 700;
    font-size: 64px;
    color: #021829;

    b {
                position: absolute;
                top: 85px;
                color: #246EFF;
            }
    }

    .adv-info {
      position: absolute;
    top: 325px;
    left: 84px;
    width: 758px;
    height: 112px;
    font-size: 16px;
    color: #777E90;
    line-height: 28px;
    }
  }

  .ecTitle {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    margin-top: 200px;
  }

  em {
    width: 94px;
    height: 8px;
    border-radius: 4px;
    background-color: #0043b3;
    display: block;
    margin: 25px auto 0px;
  }

  .ec-main{
    box-sizing: border-box;
    width: 100%;
    height: 1000px;
    padding: 70px 62px;

    .ec-contact{
      background: url('../assets/images/contact2.png') no-repeat center;
      height: 652px;
    }
    .hello{
      font-family: 'Poppins';
    overflow: hidden;
    padding-top: 66px;
    padding-left: 74px;
  }
    .Gmail{
      font-family: '思源黑体';
    overflow: hidden;
    padding-top: 60px;
    padding-left: 74px;
}

.Address{
  font-family: '思源黑体';
    overflow: hidden;
    padding-top: 50px;
    padding-left: 74px;
}

.hello h5{
  font-size: 26px;
    color: #1A315B;
}

.Address h5,
.Gmail h5
{
    line-height: 20px;
    font-size: 20px;
    color: #1A315B;
}
.Gmail p,
.Address p,
.hello p
{
  width: 717px;
  line-height: 36px;
    margin-top: 10px;
    font-size: 16px;
    color: #1A315B;
}
  }

/*  @media only screen and (max-width: 990px) {
    .advantage{
      padding-top: 500px;
    }
    .ecTitle {
      font-size: 80px;
    }

    em {
      width: 253px;
      height: 12px;
      border-radius: 10px;
      background-color: #0043b3;
      display: block;
      margin: 45px auto 120px;
    }

    .coWrap {
      background: #141f2b;

      .ecWrap {
        justify-content: space-between;
        padding: 34vh 0px;
        box-sizing: border-box;
        .ecContent {
          img {
            width: 120px;
          }
        }
      }
    }
    .city1Ad{
      width:80%;
    }
      .en{
      width: calc(100%);
      padding:30px 200px 40px 150px;
      // background-size: 100% 100%;
      box-sizing: border-box;
      background-size: cover;
      background-repeat: no-repeat;
      background-position:center center;
      background-image: url("../assets/images/lwt_en.png");
      background-repeat: no-repeat;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
    }
    .zh{
    width: calc(100%);
      padding:30px 200px 40px 150px;
      box-sizing: border-box;
      // background-size: 100% 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position:center center;
      background-image: url("../assets/images/ltw.png");
      background-repeat: no-repeat;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
  }
  .address,
  .email {
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
  }*/
}
</style>
<!-- 移动端 -->
<style lang="less" scoped>
  @media only screen and (max-width: 990px) {
    .contact{
      .advantage {
          background: url(../assets/images/shift-bg5.png) no-repeat center top;
          background-size: 7.5rem 7.83rem;
          height: 7.83rem;

          .w {
              width: 6.1rem;

              .title {
                  width: 100%;
                  font-size: 0.45rem;
                  top: 1rem;
                  left: -0.1rem;
                  b {
                      top: 0.7rem;
                  }
              }
              .adv-info {
                  font-size: 0.24rem;
                  padding: 0;
                  top: 2.6rem;
                  width: 3.9rem;
                  left: -0.1rem;
                  line-height: 0.34rem;
                  color: #777E90;
              }
          }
      }
      .ec-main{
        box-sizing: border-box;
        width: 100%;
        height: 7.43rem;
        padding: 0.33rem 0.27rem;
        .ec-contact{
          background: url('../assets/images/contact2.png') no-repeat center;
          width: 6.94rem;
          height: 6.57rem;
        }
        .hello{
            font-family: 'Poppins';
          overflow: hidden;
          padding-top: 0.62rem;
          padding-left: 0.34rem;
          h5{
            font-size: 0.31rem;
              color: #081022;
          }
          p{
            width: 6.31rem;
            line-height: 0.31rem;
              margin-top: 0.17rem;
              font-family: 'Poppins';
              font-size: 0.24rem;
              color: #0D2A66;
          }
        }
        .Gmail{
          font-family: '思源黑体';
          overflow: hidden;
          padding-top: 0.78rem;
          padding-left: 0.34rem;
          img{
            width: 0.22rem;
            height: 0.22rem;
          }
          h5{
            line-height: 0.2rem;
            font-size: 0.2rem;
            color: #1A315B;
          }
          p{
            width: 2.18rem;
            line-height: 0.2rem;
            margin-top: 0.34rem;
            font-size: 0.2rem;
            color: #1A315B;
          }
}
      .Address{
        font-family: '思源黑体';
        overflow: hidden;
        padding-top: 0.43rem;
        padding-left: 0.34rem;
        img{
          width: 0.22rem;
          height: 0.22rem;
        }
        h5{
          font-size: 0.2rem;
          font-weight: 8.72rem;
        }
        p{
          width: 2.18rem;
          line-height: 0.2rem;
          margin-top: 0.1rem;
          font-size: 0.2rem;
          color: #1A315B;
        }
      }
      }
    }
  }
</style>
