export default {
    methods: {
        changeLanguage(lang) {
            this.$store.commit(
                'SETLANGUAGE',
                lang
            )
            this.lang = false
        }
    }
}
